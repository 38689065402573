import React, { useState, useEffect } from 'react';
import { Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import secureStorage from '../../config/encrypt';
import loanbox_Illustration from "../../webroot/images/sfa_node_bg.svg";
import logo from "../../webroot/images/Frame_logo.svg";
import ls from "local-storage";
import { MasterService } from '../../services';
import { toast } from 'react-toastify';
import InputField from '../element/Input';
import Captcha from '../common/Captcha';
import Loader from '../common/loader';
import { EMAIL_VALIDATION, REDIRECT_URL } from '../../config/constant';

const Login = (props) => {
    const historyUser = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, SetErrors] = useState({});
    const [login_detail, SetLoginDetail] = useState({});
    const [reloadCaptcha, setReloadCaptcha] = useState(0);
    const [showOtp, setShowOtp] = useState(false);
    const [otpTimer, setOtpTimer] = useState(60);
    const [otpValue, setOtpValue] = useState('');
    const [inputFieldCaptcha, SetInputFieldCaptch] = useState({ captcha: "" });
    const [ user, setUser ] = useState([]);

    useEffect(() => {
        let header = document.getElementsByClassName("header")
        header[0].classList.add("hidden")
        let footer = document.getElementsByClassName("footer")
        footer[0].classList.add("hidden")
        let logged_data = ls.get('logged_data')
        if (logged_data && logged_data.token && logged_data.user_data && logged_data.user_data.user_id) {
            ls.set("is_redirect", 2)
            historyUser("/");
        } else{
            ls.set("is_redirect", 0)
        }
        let login_email = ls.get('login_email')
        if(login_email){
            setLoading(true)
            let temp_login_details = { ...login_detail };
            temp_login_details.login_username = login_email
            SetLoginDetail(temp_login_details)
        }
    }, []);
    ls.set('filter_data', {});
    ls.set('current_status', '');

    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    
    useEffect(() => {
        if (window.location.search) {
            let search_params = getParameterByName('userdata')
            if (search_params) {
                search_params = JSON.parse(search_params)
                setUser(search_params)
                let login_username = search_params?.email || ""
                ls.set('login_email', login_username);
                window.location.href = "/"
            }
        }
    }, [window.location.search])

    useEffect(() => {
        if (!otpTimer) return;

        const intervalId = setInterval(() => {
            setOtpTimer(otpTimer - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [otpTimer])

    const handleLogin = async (is_google_login = false) => {
        let res = await isValidate()
        if (res) {
            setLoading(true);
            let params = {
                captchaId: login_detail.captchaId,
                captchaVal: login_detail.captchaVal,
                username: login_detail.login_username,
                password: login_detail.password,
                is_google_login: is_google_login
            }
            MasterService.post('/sfa/user/login-by-email', params)
                .then(function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data) {
                            // handleSendOTP()
                            ls.set('logged_data', response.data.data);
                            secureStorage.setItem('loginUserInfo', response.data.data);
                            let path = window?.navigator?.userAgentData?.platform == "Windows" ? "/" : "/user-management"
                            window.location.href = path
                        } else if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                setReloadCaptcha(reloadCaptcha + 1)
                                resetCaptcha()
                                toast.error(element);
                                setUser([])
                                setLoading(false)
                            });
                        }
                    } else {
                        setReloadCaptcha(reloadCaptcha + 1)
                        resetCaptcha()
                        if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setUser([])
                                setLoading(false)
                            });
                        } else if (response.data.message) {
                            toast.error(response.data.message);
                            setUser([])
                            setLoading(false)
                        } else {
                            setLoading(false);
                            setUser([])
                            toast.error("Failed");
                        }
                    }
                })
                .catch(function (error) {
                    toast.error(error);
                    setUser([])
                    setLoading(false);
                });
        }
    }

    const handleGooglelogin = (e, i) => {
        MasterService.post('sfa/user/google_url', {}).then(function (response) {
            if (response.status === 200 && response.data.status === 200) {
                window.location = response.data.data + "?redirect=" + REDIRECT_URL
            }
        }).catch(function (error) {
            console.log(error.message);
        });
    }

    useEffect(() => {
        if (login_detail.login_username) {
            let is_google_login = true
            handleLogin(is_google_login)
        }
    },[login_detail.login_username]);

    const resetCaptcha = () => {
        let tempLoginDetail = { ...login_detail };
        tempLoginDetail.captchaVal = ""
        SetLoginDetail(tempLoginDetail)
    }

    const isValidate = async (value) => {
        let tempLoginDetail = { ...login_detail };
        let formIsValid = true;
        let tempErrors = {};
        if (value === "send_otp") {
            if (!tempLoginDetail["login_username"]) {
                formIsValid = false;
                tempErrors["login_username"] = 'Email is required';
            } else if (tempLoginDetail["login_username"].match(EMAIL_VALIDATION) == null) {
                tempErrors["login_username"] = 'Email is not valid';
            }
        } else if (value === "validate_otp_captcha") {
            if (!tempLoginDetail["otp"]) {
                formIsValid = false;
                tempErrors["otp"] = 'OTP is required';
            }
            if (!tempLoginDetail["captchaId"] || !tempLoginDetail["captchaVal"]) {
                formIsValid = false;
                tempErrors["captcha"] = 'Captcha is required';
            }
        } else if (value === "validate_otp") {
            if (!tempLoginDetail["otp"]) {
                formIsValid = false;
                tempErrors["otp"] = 'OTP is required';
            }
        } else {
            if (!tempLoginDetail["login_username"]) {
                formIsValid = false;
                tempErrors["login_username"] = 'Email is required';
            }
        }
        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        SetErrors(tempErrors);
        return formIsValid;
    }

    const handleInputChange = async (target_name, type, event) => {
        let temp_login_details = { ...login_detail };
        let tempErrors = { ...errors }
        temp_login_details[target_name] = event.target.value;
        tempErrors[target_name] = ""
        SetErrors(tempErrors)
        SetLoginDetail(temp_login_details);
    }

    const captchaFiledHandler = (childData) => {
        let captchaId = childData?.captchaData?.data?.value;

        let tempErrors = { ...errors }
        let temp_login_details = { ...login_detail };
        if (captchaId) {
            tempErrors['captchaErr'] = "";
            temp_login_details['captchaVal'] = childData.captchaVal;
            temp_login_details['captchaId'] = childData.captchaData.data.value;
        } else {
            tempErrors['captchaErr'] = "Please enter valid captcha.";
        }
        SetLoginDetail(temp_login_details);
        SetErrors(tempErrors)
    }

    const handleSendOTP = async () => {
        let res = await isValidate("send_otp")
        if (res) {
            setLoading(true);
            let params = {
                username: login_detail.login_username
            }
            MasterService.post('/sfa/user/send_admin_otp', params)
                .then(function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data && response.data.data.value) {
                            setShowOtp(true)
                            setOtpValue(response.data.data.value)
                            setLoading(false);
                            setOtpTimer(60)
                            toast.success(response.data.message)
                        } else if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setUser([])
                                setLoading(false)
                            });
                        }
                    } else {
                        if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setUser([])
                                setLoading(false)
                            });
                        } else if (response.data.message) {
                            toast.error(response.data.message);
                            setUser([])
                            setLoading(false)
                        } else {
                            setUser([])
                            setLoading(false);
                            toast.error("Failed");
                        }
                    }
                })
                .catch(function (error) {
                    setUser([])
                    toast.error(error);
                    setLoading(false);
                });
        }
    }

    const handleSubmit = async (with_captcha) => {
        let res = with_captcha ? await isValidate("validate_otp_captcha") : await isValidate("validate_otp")
        if (res) {
            setLoading(true);
            let params = {
                username: login_detail.login_username,
                otp: otpValue,
                otp_value: login_detail.otp,
                captchaId: login_detail.captchaId,
                captchaVal: login_detail.captchaVal,
                with_captcha: with_captcha
            }
            MasterService.post('/sfa/user/validate_admin_otp', params)
                .then(function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data) {
                            ls.set('logged_data', response.data.data);
                            secureStorage.setItem('loginUserInfo', response.data.data);
                            let path = window?.navigator?.userAgentData?.platform == "Windows" ? "/" : "/user-management"
                            window.location.href = path
                        } else if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setReloadCaptcha(reloadCaptcha + 1)
                                resetCaptcha()
                                setLoading(false)
                            });
                        }
                    } else {
                        setReloadCaptcha(reloadCaptcha + 1)
                        resetCaptcha()
                        if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setLoading(false)
                            });
                        } else if (response.data.message) {
                            toast.error(response.data.message);
                            setLoading(false)
                        } else {
                            setLoading(false);
                            toast.error("Failed");
                        }
                    }
                })
                .catch(function (error) {
                    console.log("error", error)
                    toast.error(error);
                    setLoading(false);
                });
        }
    }

    const handleClickTab = () => {
        let tempErrors = { ...errors }
        tempErrors = {}
        SetErrors(tempErrors)
    }

    return (
        <div className="container-fluid">
            {loading ? <Loader /> : null}
            <div className="row">

                <div className='login-main'>

                    <div className="login-outer">
                        <div className="login-left-panel">
                            <div className="login-bg">
                                <div className="logo-img">
                                    {<img src={logo} className="" />}
                                </div>
                                <div className="login-txt">

                                    <h1>Welcome to Carmudi SFA</h1>
                                    {/* <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</span> */}
                                </div>
                                <div className='login-bg-img'>
                                    {<img src={loanbox_Illustration} className="" />}
                                </div>
                            </div>
                        </div>
                        <div className="login-right-panel">
                            <div className="login-heading">
                                <h2>Login </h2>

                            </div>
                            <Tab.Container id="" defaultActiveKey="first">
                                <div className="login-tab">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className="login-form">
                                                {!showOtp &&
                                                    <div>
                                                        {user.length == 0 ?
                                                            <button className="google-sign-in-button" onClick={()=>handleGooglelogin()}>Sign in with Google 🚀 </button>
                                                            : <button className="google-sign-in-button" disabled = {true}>Sign in with Google 🚀 </button>
                                                        }
                                                    </div>
                                                }
                                                {showOtp &&
                                                    <div>
                                                        <fieldset className="">
                                                            <div className={errors.otp ? "material error" : "material"}>
                                                                <InputField type="text" name="otp" id="otp" label="OTP" placeholder=" " value={login_detail.otp} onChange={handleInputChange.bind(null, 'otp', 'text')} required={true} />
                                                                <i className="ic-otp icn-login">
                                                                    <i className="path1"></i>
                                                                    <i className="path2"></i>
                                                                    <i className="path3"></i>
                                                                    <i className="path4"></i>
                                                                </i>
                                                                <label data-label="OTP" className="form-label"></label>
                                                                {errors.otp && <span className="erroe-txt">{errors.otp}</span>}
                                                                {otpTimer > 0 ?
                                                                    <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span> :
                                                                    <span className="otp-time" style={{ cursor: "pointer" }} onClick={() => { handleSendOTP() }}>Resend</span>
                                                                }

                                                                <div className="otp-success-icon" style={{ display: "none" }} >
                                                                    <i className="ic-check"></i>
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                        <fieldset className="">
                                                            <div className={errors.captcha ? "material error" : "material"}>
                                                                <Captcha className="form-input" onKeyPressCaptcha={captchaFiledHandler} errField={errors} inputField={inputFieldCaptcha} reloadCaptcha={reloadCaptcha} />
                                                                {errors.captcha && <span className="erroe-txt">{errors.captcha}</span>}
                                                            </div>
                                                        </fieldset>
                                                        <button type="button" className="btn-primary" onClick={() => { handleSubmit(true) }}>Submit</button>
                                                    </div>
                                                }
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className="login-form">
                                                <fieldset className="">
                                                    <div className={errors.login_username ? "material error" : "material"}>
                                                        <InputField type="text" name="login_username" id="login_username" label="Email" placeholder=" " value={login_detail.login_username} onChange={handleInputChange.bind(null, 'login_username', 'text')} required={true} />
                                                        <i className="ic-email1 icn-login"></i>
                                                        <label data-label="Email" className="form-label"></label>
                                                        {errors.login_username && <span className="erroe-txt">{errors.login_username}</span>}
                                                    </div>
                                                </fieldset>
                                                {showOtp &&
                                                    <div>
                                                        <fieldset className="">
                                                            <div className={errors.otp ? "material error" : "material"}>
                                                                <InputField type="text" name="otp" id="otp" label="OTP" placeholder=" " value={login_detail.otp} onChange={handleInputChange.bind(null, 'otp', 'text')} required={true} />
                                                                <i className="ic-otp icn-login">
                                                                    <i className="path1"></i>
                                                                    <i className="path2"></i>
                                                                    <i className="path3"></i>
                                                                    <i className="path4"></i>
                                                                </i>
                                                                <label data-label="OTP" className="form-label"></label>
                                                                {errors.otp && <span className="erroe-txt">{errors.otp}</span>}
                                                                {otpTimer > 0 ?
                                                                    <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span> :
                                                                    <span className="otp-time" style={{ cursor: "pointer" }} onClick={() => { handleSendOTP() }}>Resend</span>
                                                                }

                                                                <div className="otp-success-icon" style={{ display: "none" }} >
                                                                    <i className="ic-check"></i>
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                        <fieldset className="">
                                                            <div className={errors.captcha ? "material error" : "material"}>
                                                                <Captcha className="form-input" onKeyPressCaptcha={captchaFiledHandler} errField={errors} inputField={inputFieldCaptcha} reloadCaptcha={reloadCaptcha} />
                                                                {errors.captcha && <span className="erroe-txt">{errors.captcha}</span>}
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                }
                                                {!showOtp && <button type="button" className="btn-primary" onClick={() => { handleSendOTP() }}>Get OTP</button>}
                                                {showOtp && <button type="button" className="btn-primary" onClick={() => { handleSubmit(true) }}>Submit</button>}
                                            </div>
                                        </Tab.Pane>


                                    </Tab.Content>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Login;