import MasterService from "./MasterService";

const InsuranceBoxService = {
    ...MasterService,
    getAgencyInsuranceStatus(postdata) {
        return this.post(`insurancebox/lead/getAgencyInsuranceStatus`, postdata);
    },
};

export default InsuranceBoxService;
