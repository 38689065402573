import React, { useState, useEffect } from 'react';
import { Role } from '../../config/constant';


const HierarchyDetails = (props) => {

    const [managersData,setManagersData] = useState({});


    useEffect(()=>{
        //console.log("props.allManagerList====>",props.allManagerList)
        let tempManagersData = {...managersData};
        if (props.allManagerList && props.allManagerList.length) {                    
            for (let data of props.allManagerList) {
                if(data.role_id == Role['Sales Manager']){
                    tempManagersData['Sales Manager']={
                        id: data.id,
                        name: data.name,
                    }
                }
                if(data.role_id == Role['Territory Head']){
                    tempManagersData['Territory Head']={
                        id: data.id,
                        name: data.name,
                    }
                }
                if(data.role_id == Role['Sales Head']){
                    tempManagersData['Sales Head']={
                        id: data.id,
                        name: data.name,
                    }
                }
                if(data.role_id == Role.President){
                    tempManagersData['President']={
                        id: data.id,
                        name: data.name,
                    }
                }  
                if(data.role_id == Role['ARO']){
                  tempManagersData['ARO']={
                      id: data.id,
                      name: data.name,
                  }
              }                                                                   
            }
        }

        setManagersData(tempManagersData);
    },[props.allManagerList])



    return (
      <div className="reporting-line-detail">
        <div className="modal-header">
          <h2>Reporting Line</h2>
          <span>
            {"User Details"}
          </span>
        </div>
        {props.allManagerList ? (
          <div className='modal-body'>
            {managersData['ARO']?.id ? <> <div className="detail">
              <div>{"ARO"}</div>
              <div>{managersData['ARO']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"ARO ID"}</div>
              <div>{managersData['ARO']?.id || "-"}</div>
            </div></> : null}
            {managersData['Sales Manager']?.id ? <> <div className="detail">
              <div>{"Sales Manager Name"}</div>
              <div>{managersData['Sales Manager']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"Sales Manager ID"}</div>
              <div>{managersData['Sales Manager']?.id || "-"}</div>
            </div></> : null}
            {managersData['Territory Head']?.id ? <> <div className="detail">
              <div>{"Territory Head Name"}</div>
              <div>{managersData['Territory Head']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"Territory Head ID"}</div>
              <div>{managersData['Territory Head']?.id || "-"}</div>
            </div></> : null}
            {managersData['Sales Head']?.id ? <> <div className="detail">
              <div>{"Sales Head Name"}</div>
              <div>{managersData['Sales Head']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"Sales Head ID"}</div>
              <div>{managersData['Sales Head']?.id || "-"}</div>
            </div></> : null}
            {managersData['President']?.id ? <> <div className="detail">
              <div>{"President Name"}</div>
              <div>{managersData['President']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"President ID"}</div>
              <div>{managersData['President']?.id || "-"}</div>
            </div></> : null}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

export default HierarchyDetails;