import React, { useEffect, useState, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
// import Tooltip from 'react-tooltip-lite';


const RoleHistorylistingTable = (props) => {
    const csvLink = useRef();
    const [csvData, setCsvData] = useState([]);
    const [pageCount, setPageCount] = useState(1)
    const [roleHistoryData, setRoleHistoryData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [remarkTip, setRemarkTip] = useState({
        index:0,
        status:false,
    });

    useEffect(() => {
        setRoleHistoryData(props.roleHistoryData)
        setPageCount(props.pageCount)
    }, [props.roleHistoryData, props.pageCount])

    useEffect(()=> {
        setOffset(0)
    }, [props.isPageReset])

    const downloadData = async () => {
        let csv_data = await props.getExportData();
        setCsvData(csv_data)
        csvLink.current.link.click();
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage)
        props.getRoleHistoryData(selectedPage + 1, {})
    };

    return (
        <React.Fragment>

            <div className="top-heading d-flex">
                <h2></h2>
                <button className="btn-line">
                    <i className="ic-export m-xs-r"></i>
                    <span onClick={() => downloadData()} >Export Data</span>
                    <CSVLink
                        data={csvData}
                        filename={`role-history-list-${Math.round(+new Date() / 1000)}.csv`}
                        data-interception='off'
                        ref={csvLink}
                    >
                    </CSVLink>
                </button>
            </div>
            <div className='data-table'>
                <table>
                    <thead>
                        <tr>
                            <th>SFA ID</th>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Type</th>
                            <th>Email ID</th>
                            <th>Business Line</th>
                            <th>Status</th>
                            <th>Created By</th>
                            <th>Created On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {roleHistoryData?.length > 0 && roleHistoryData.map((element, index) => (
                            <tr key={element.id}>
                                <td><span className='d-block'>{element["sfa_user_id"] ? element["sfa_user_id"] : "-"}</span></td>
                                <td><span className='d-block wrap-cell'>{element["name"] ? element["name"] : "-"}</span></td>
                                <td><span className='d-block'>{element["role_name"] ? element["role_name"] : "-"}</span></td>
                                <td><span className='d-block'>{element["employe_type"] ? element["employe_type"] : "-"}</span></td>
                                <td><span className='d-block wrap-cell'>{element["email"] ? element["email"] : "-"}</span></td>
                                <td><span className='d-block wrap-cell'>{element["business_line"] ? element["business_line"] : "-"}</span></td>
                                <td><span className='d-block wrap-cell'>{element["user_status_name"] ? element["user_status_name"] : "-"}</span></td>
                                <td><span className='d-block wrap-cell'>{element["created_by"] ? element["created_by"] : "-"}</span></td>
                                <td><span className='d-block wrap-cell'>{element["created_date"] ? element["created_date"] : "-"}</span></td>
                            </tr>
                            
                        ))}

                    </tbody>
                </table>
                <div className='pagination-bx' id="dealerUserList">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        forcePage={offset}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div >
            </div>

        </React.Fragment>
    )
}

export default RoleHistorylistingTable;