import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import language_id from "./translations/id/language.json";
import language_en from "./translations/en/language.json";
import secureStorage from "./config/encrypt";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";

i18next.init({
	interpolation: { escapeValue: false },
	lng: secureStorage.getItem("lang") === "id" ? "id" : "en",
	lng: "en",
	resources: {
		en: {
			common: language_en,
		},
		id: {
			common: language_id,
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18next}>
			<Provider store={store}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();