import React, { useEffect, useState } from 'react';
// import Tooltip from 'react-tooltip-lite';
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import InputField from '../common/Input';
const SalesMappedDealer = (props) => {
    const [tipOpen, setTipOpen] = useState(false);
    const [tipOpenId, setTipOpenId] = useState(0);
    const [search_text, setSearchText] = useState('')
    const [assigned_dealer_data, setAssignedDealerData] = useState(null)
    const [unassigned_dealer_data, setUnassignedDealerData] = useState(null)

    useEffect(() => {
        setAssignedDealerData(props.assigned_dealer)
        setUnassignedDealerData(props.unAssigned_dealer)
        setTipOpenId('')
    }, [props.assigned_dealer, props.unAssigned_dealer])

    useEffect(() => {
        setSearchText("")
    }, [props.search_reset])

    const toggleTip = (id, state) => {
        if (state === 'close') {
            id = ''
        }
        setTipOpen(!tipOpen)
        setTipOpenId(id)
    }

    const handleInputChange = async (target_name, type, event) => {
        let { value } = event.target
        if (target_name === "search_text") {
            setSearchText(value)
            if (value.length > 2) {
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, search_text: value }
                await props.handleUnAssignedDealer(params, assigned_dealer_data)
            }
            if (value.length == 0) {
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, search_text: "" }
                await props.handleUnAssignedDealer(params, assigned_dealer_data)
            }
        }
    }

    const handleClick = (element, index, type) => {
        let temp_unassigned_dealer_data = [...unassigned_dealer_data]
        let temp_assigned_dealer_data = [...assigned_dealer_data]
        if (type === 'unassigned') {
            temp_unassigned_dealer_data = temp_unassigned_dealer_data.filter((e) => e.dealer_id != element.dealer_id)
            temp_assigned_dealer_data.unshift(element)
        }
        if (type === 'assigned') {
            temp_assigned_dealer_data = temp_assigned_dealer_data.filter((e) => e.dealer_id != element.dealer_id)
            temp_unassigned_dealer_data.unshift(element)
        }
        setAssignedDealerData(temp_assigned_dealer_data)
        setUnassignedDealerData(temp_unassigned_dealer_data)
    }

    const handleSubmit = async () => {
        let assigned_dealer_id = assigned_dealer_data.map(({ dealer_id }) => dealer_id)
        props.updateAssignDealer(assigned_dealer_id)
        setSearchText("")
    }

    return (
        <React.Fragment>
            <div className='sales-users-left-panel'>
                <h3>Sales Dealers</h3>
                <div className='sales-mapped-bg'>
                    <fieldset class="search-bx">
                        <div class="material">
                            <InputField type="text" name="search_text" id="search_text" placeholder="Search Dealer" value={search_text} onChange={handleInputChange.bind(null, 'search_text', 'text')} />
                            <i class="ic-search"></i>
                        </div>
                    </fieldset>
                    <div className='sales-user-list'>
                        <ul>
                            {unassigned_dealer_data && unassigned_dealer_data.length > 0 && unassigned_dealer_data.map((element, index) => (
                                <li key={index}>
                                    <label onClick={(e) => handleClick(element, index, 'unassigned')}>{element.name + (element.dealer_id ? " (GCD - " + element.dealer_id + ")" : "")}</label>
                                    {element.dealercount > 0 &&
                                        <span className='mapped_user_toggle'>{element.dealercount + " Mapped Users"}
                                            <i onClick={() => toggleTip(element.dealer_id, 'open')} className='ic-info-icon1 m-sm-l'></i>
                                            <span title="" className="tooltio-ic">

                                            </span>
                                            {/* <span onClick={() => toggleTip(element.dealer_id, 'open')} title="" className="tooltio-ic">
                                                <i className='ic-info-icon1 m-sm-l'></i>
                                            </span> */}
                                            {/* <Tooltip
                                                content={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">
                                                            <span className="controlled-example_close-button" onClick={() => toggleTip(element.dealer_id, 'close')}><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                {element.assigned_executive_data?.length && element.assigned_executive_data.map((executive_element, executive_index) => (
                                                                    <li key={executive_index}>{executive_element.name+" - "+executive_element.user_id}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                isOpen={(element.dealer_id === tipOpenId) && tipOpen ? true : false}
                                                tagName="span"
                                                direction="down"
                                                className="tootip-outer"
                                                forceDirection
                                            >
                                            </Tooltip> */}
                                            <Tooltip
                                                useContext
                                                html={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data tootip-outer">
                                                            <span className="controlled-example_close-button" onClick={() => toggleTip(element.dealer_id, 'close')}><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                {element.assigned_executive_data?.length && element.assigned_executive_data.map((executive_element, executive_index) => (
                                                                    <li key={executive_index}>{executive_element.name + " - " + executive_element.user_id}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                className="tootip-outer"
                                                // open="true"
                                                theme="light"
                                                size="regular"
                                                position="bottom"
                                                trigger="click"
                                            />
                                        </span>
                                    }
                                    <i onClick={(e) => handleClick(element, index, 'unassigned')} className='ic-arrow_forward listclose-ic'></i>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className='mapped-users-right-panel'>
                <h3>Mapped Sales Dealers</h3>
                <div className='sales-mapped-bg'>

                    <div className='sales-user-list'>
                        <ul>
                            {assigned_dealer_data && assigned_dealer_data.length > 0 && assigned_dealer_data.map((element, index) => (
                                <li key={index}>
                                    <label>{element.name + (element.dealer_id ? " (GCD - " + element.dealer_id + ")" : "")}</label>
                                    {element.dealercount > 0 &&
                                        <span className='mapped_user_toggle'>{element.dealercount + " Mapped Users"}
                                            {/* <i onClick={() => toggleTip(element.dealer_id, 'open')} className='ic-info-icon1 m-sm-l'></i>
                                            <span title="" className="tooltio-ic">

                                            </span>
                                            <span onClick={() => toggleTip(element.dealer_id, 'open')} title="" className="tooltio-ic">
                                                <i className='ic-info-icon1 m-sm-l'></i>
                                            </span> */}
                                            {/* <Tooltip
                                                content={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">
                                                            <span className="controlled-example_close-button" onClick={() => toggleTip(element.dealer_id, 'close')}><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                {element.assigned_executive_data?.length && element.assigned_executive_data.map((executive_element, executive_index) => (
                                                                    <li key={executive_index}>{(executive_element.name ? executive_element.name + " - " + executive_element.user_id : "") + (executive_element.role_name ? " (" + executive_element.role_name + ")" : "") + (executive_element.manager_name ? ", " + executive_element.manager_name : "") + (executive_element.manager_role_name ? " (" + executive_element.manager_role_name + ")" : "")}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                isOpen={(element.dealer_id === tipOpenId) && tipOpen ? true : false}
                                                tagName="span"
                                                direction="down"
                                                className="tootip-outer"
                                                forceDirection
                                            >
                                            </Tooltip> */}
                                            
                                            <span className='tooltio-ic'>
                                                <Tooltip
                                                    useContext
                                                    html={(
                                                        <div className="controlled-example tooltip-main">
                                                            <div className="tooltip-data tootip-outer">
                                                                <ul className="user-detail">
                                                                    {element.assigned_executive_data?.length && element.assigned_executive_data.map((executive_element, executive_index) => (
                                                                        <li key={executive_element.user_id}>{(executive_element.name ? executive_element.name + " - " + executive_element.user_id : "") + (executive_element.role_name ? " (" + executive_element.role_name + ")" : "") + (executive_element.manager_name ? ", " + executive_element.manager_name : "") + (executive_element.manager_role_name ? " (" + executive_element.manager_role_name + ")" : "")}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )}
                                                    className="tootip-outer"
                                                    // open="true"
                                                    theme="light"
                                                    size="regular"
                                                    position="bottom"
                                                    trigger="click"
                                                >
                                                    <i className='ic-info-icon1 m-sm-l'></i>
                                                </Tooltip>
                                            </span>
                                        </span>
                                    }
                                    <i onClick={(e) => handleClick(element, index, 'assigned')} className='ic-clearclose listclose-ic'></i>
                                </li>
                            ))}

                        </ul>
                        <div onClick={() => handleSubmit()} className='btn-submit'>
                            <button className='btn-primary'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mapped-users-right-panel' style={{ "display": 'none' }}>
                <h3>Mapped Sales Users</h3>
                <div className='sales-mapped-bg'>

                    <div className='sales-user-list no-data-txt'>
                        <ul>
                            <li>Move Sales Officer here</li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
    // }
}
export default SalesMappedDealer;