import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import secureStorage from './../../config/encrypt';
import ls, { get, set } from "local-storage";


// class Logout extends Component{
//     componentDidMount() {
//         ls.set('logged_user_id',0);
//         ls.set('logged_data',{});        
//         secureStorage.removeItem('loginUserInfo');
//         this.props.updateStateIntoRedux('LOGOUT_USER', '');  
//         this.props.history.push("/login")
//     }
//     render(){       
//         return('');
//     }
// }
const Logout = (props) => {
    useEffect(() => {
        ls.set('logged_user_id', 0);
        ls.set('logged_data', {});
        ls.set('login_email', "");
        secureStorage.removeItem('loginUserInfo');
        props.updateStateIntoRedux('LOGOUT_USER', '');
        let path = window?.navigator?.userAgentData?.platform == "Windows" ? "/" : "/user-management"
        window.location.href = path
    }, []);
    return ('')
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default connect(null, mapDispatchToProps)(Logout);