import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { MasterService } from '../../services';
import ReactMultiSelectCheckboxes from "../common/ReactMultiSelectCheckboxes"
import { Calendra, Calendra2 } from '../../view/view-report/DatePicker';
import { getFirstDate } from '../../config/helper';
import { VIRTUAL_ADMIN_ROLE_ID } from '../../config/constant';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const RoleHistoryReportTopFilter = (props) => {
    const [fromDate, setFromDate] = useState(getFirstDate(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [selectedRole, setSelectedRole] = useState("")
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([])
    const [roleOptions, setRoleOptions] = useState([]);

    useEffect(() => {
        if (props.isReset) {
            handleReset()
        }
    }, [props.isReset])

    useEffect(() => {
        getAllRoleList();
    },[]);

    const getAllRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
          .then(function (response) {
            if (response.data.status == 200) {
            const roles = response.data.data && response.data.data.length > 0 && response.data.data.filter(i => ![VIRTUAL_ADMIN_ROLE_ID].includes(i.id))
            const modifiedroles = roles.map(item =>
                item.name === "National Sales Head" ? { ...item, name: "NSH" } : item
              );
            setRoleOptions(modifiedroles);
            }
          })
          .catch(function (response) {
          });
      }


    const handleChange = (event, name) => {

        if (name === "from_date") {
            setFromDate(event)
        }
        if (name === "to_date") {
            setToDate(event)
            if (new Date(event).getTime() <= new Date(fromDate).getTime()) {
                setFromDate(null)
            }
        }

        if (name === 'role') {
            setSelectedRole(event)
            getUsersByRole(event.id)
            setSelectedUsers([])
        }


    };

    const handleChangeMultiSel = (field_name, field_obj, event) => {
        let selected_value = []
        let currval = [];
        if (event.action === "select-option" && event.option.value === "*") {
            if (field_name === "users") {
                selected_value = field_name === "users" ? [{ label: "Select All", value: "*" }, ...users] : [{ label: "Select All", value: "*" }, ...selectedUsers]
            }
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            selected_value = []
        } else if (event.action === "deselect-option" && event.option.value !== "*") {
            selected_value = field_obj.filter((o) => o.value !== "*")

        } else {
            currval = field_obj.map(v => v.id);
            selected_value = field_obj
        }

        if (field_name === "users") {
            setSelectedUsers(selected_value);
        }

    }

    const handleSearch = (is_reset) => {
        if (is_reset) {
            let params = {
                start_date: getFirstDate(new Date()),
                end_date: new Date(),
                manager_id_filter: [],
                page: 1,
            }
            props.getRoleHistoryData(1, params, true)
        } else {
            let params = {
                start_date: fromDate,
                end_date: toDate,
                manager_id_filter: selectedUsers.map(v => v.id).filter(function (element) {
                    return element !== undefined;
                }),
                manager_filter_role_id: selectedRole.id,
                page: 1,
            }

            props.getRoleHistoryData(1, params, true) //need to do work
        }
    }

    const handleReset = () => {
        setFromDate(getFirstDate(new Date()))
        setToDate(new Date())
        handleSearch(true)
        setSelectedRole("");
        setSelectedUsers([]);
        // setSelectedAttendanceStatus([])
    }

    const getUsersByRole = async (role_id) => {
        MasterService.post('/sfa/user/get-users', { role_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_manager_list = response.data.data.user_list.map((elm)=> { if(elm) {return {id: elm.id, label: elm.name, value: elm.name }}});
                    const filteredData = reporting_manager_list.filter(item => item !== undefined);
                    setUsers(filteredData);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
            <div className=''>
                <div className="visit-report-filter">
                    <fieldset className="rangepicker-calendra">
                        <div className="material">
                            <Calendra
                                placeholder={"Date From"}
                                defaultDate={fromDate}
                                endDate={toDate}
                                handleDateChange={(e) => handleChange(e, 'from_date')}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="rangepicker-calendra">
                        <div className="material">
                            <Calendra2
                                placeholder={"To Date"}
                                startDate={fromDate}
                                defaultDate={toDate}
                                endDate={new Date()}
                                handleDateChange={(e) => handleChange(e, 'to_date')}
                            />
                        </div>
                    </fieldset>


                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                value={roleOptions.filter(e => e.id === selectedRole.id)}
                                onChange={(e) => handleChange(e, 'role')}
                                options={roleOptions}
                                placeholder="Role"
                                className="react-select"
                                classNamePrefix="react-select"
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                                // menuIsOpen={true}
                            />
                        </div>
                    </fieldset>

                    <fieldset className="multiselect-dropDown col-md-4">
                        <div className="material">
                            
                            <ReactMultiSelectCheckboxes
                                options={[{ label: "Select All", value: "*" }, ...users]}
                                value={selectedUsers?.length === users?.length ? [{ label: "Select All", value: "*" }, ...users] : selectedUsers}
                                onChange={handleChangeMultiSel.bind(this, "users")}
                                name="users"
                                // placeholder="User*"
                                isDisabled={false}
                                isClearable={true}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                            />
                        </div>
                    </fieldset>



                    <div className="btn-submit-reset">
                        <button type="submit" className="btn-primary" onClick={() => { handleSearch(false) }}>Update</button>
                        <button className="btn-reset" onClick={() => handleReset()}>Reset</button>
                    </div>
                </div>

            </div>
    )
}
export default RoleHistoryReportTopFilter;