import React, {useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import ReactMultiSelectCheckboxes from '../common/ReactMultiSelectCheckboxes'
import MasterService from '../../services/MasterService';
import { defaultZone, Zone, VIRTUAL_ADMIN, Business, defaultBusiness, Vertical, defaultVertical } from '../../config/constant';
import { DealerService } from '../../services';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import InsuranceBoxService from '../../services/InsuranceBoxService';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const RoleDetails = (props) => {
    const { stateList, user_detail, updateBasicDetail } = props
    const [roleList, setRoleList] = useState([])
    const [cityData, setCityData] = useState(null);
    const [stateData, setStateData] = useState(null);
    const [reportingRoleList, setReportingRoleList] = useState([])
    const [reportingManagerList, setReportingManagerList] = useState([])
    const [roleDetails, setRoleDetails] = useState({ zone_id: defaultZone, })
    const [roleChange, setRoleChange] = useState(false)
    const [errors, setErrors] = useState(props?.location_state?.tempErrors ? props.location_state.tempErrors : {});
    const [cityList, setCityList] = useState([])
    const [loading, setLoading] = useState(false);
    const [is_edit, setEdit] = useState(user_detail && user_detail.user_id && user_detail.user_id !== "" ? true : false)
    const [role_validation_error_msg, setRoleValidationErrorMsg] = useState('');
    const [business_vertical_id,  setBusinessVertialId] = useState(user_detail?.role_details?.business_vertical_id ?? defaultVertical);
    const [businessLineList, setBusinessLineList] = useState([]);
    const [changeBusinessLine, setChangeBusinessLine] = useState(true)
    const historyLead = useNavigate();

    const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        let text = placeholderButtonLabel.split("*")[0]
        if (value && value.some((o) => o.value === "*")) {
            return `All ${text} selected`;
        } else if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            if (text === "State" && value?.length === stateList.length) {
                return `All ${text} selected`;
            } else if (text === "City" && value?.length === cityList.length) {
                return `All ${text} selected`;
            } else {
                return `${value.length} ${text} selected`;
            }
        } else {
            return `${placeholderButtonLabel}`;
        }
    }

    useEffect(() => {
        getRoleList()
        getBusinessLineList()
        if (user_detail.role_details) {
            let role_details = user_detail.role_details
            role_details.zone_id = defaultZone
            setRoleDetails(role_details)
            getCityAndStateData(role_details, true)
            getReportingRole(role_details.role)
            getUsersByRole(role_details.reporting_role, role_details.business_line)
            businessLineChangeValidation(role_details)
            if (user_detail.user_id) {
                setEdit(true)
            }
        }
    }, [user_detail])


    // useEffect(() => {
    //     if(reportingRoleList.length)
    //     handleChange('reporting_role', reportingRoleList[0])
    // },[reportingRoleList]);

    // useEffect(() => {
    //     if(roleDetails.length)
    //     handleChange('reporting_manager', roleDetails[0])
    // },[roleDetails]);

    const getCityAndStateData = async (role_details, setData) => {
        await getCityList(role_details.state, setData, role_details)
        let state_data = stateList.filter(e => role_details.state.includes(e.id))
        if (state_data && state_data.length > 0) {
            setStateData(state_data)
        }
    }

    const getCityList = async (state_ids, setData, role_details) => {
        let temp_role_details = role_details
        if (state_ids.length === 0) {
            temp_role_details["city"] = []
            setCityData([])
            setCityList([])
        } else {
            await MasterService.post('/sfa/user/city_list', { state_ids })
                .then(function (response) {
                    if (response.data.status === 200) {
                        var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                        setCityList(city_list);
                        if (setData) {
                            let city_data = city_list.filter(e => role_details.city.includes(e.id))
                            if (city_data && city_data.length > 0) {
                                let currval = city_data.map(v => v.id);
                                temp_role_details["city"] = currval
                                setCityData(city_data)
                            } else {
                                temp_role_details["city"] = []
                                setCityData([])
                            }
                        }
                    }
                })
                .catch(function (response) {
                });
        }
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    let role_list = response.data.data.filter(e => e.name !== VIRTUAL_ADMIN)
                    role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    setRoleList(role_list)
                }
            })
            .catch(function (response) {
            });
    }

    const getBusinessLineList = async () => {
        try {
            let response = await MasterService.post('/sfa/user/business_line_list', {})
            if (response?.data?.status === 200) {
                let resposneData = response?.data?.data || [];
                resposneData = resposneData.map(item => {
                    return {
                        label: item.business_line,
                        id: item.business_line_id
                    }
                })
                setBusinessLineList(resposneData);
            } else {
                toast.error(response?.data?.message || "Error")
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const getUsersByRole = async (role_id, business_line) => {
        let params = {
            role_id: role_id
        }
        if (business_line && business_line === 'insurance_agency') {
            params.business_line = business_line
        }
        MasterService.post('/sfa/user/get-users', params)
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_manager_list = response.data.data.user_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    reporting_manager_list = reporting_manager_list.filter(e=>e.id != user_detail.user_id)
                    setReportingManagerList(reporting_manager_list)
                }
            })
            .catch(function (response) {
            });
    }
    const getReportingRole = async (role_id) => {
        MasterService.post('/sfa/user/reporting_role_list', { role_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_role_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    setReportingRoleList(reporting_role_list)
                }
            })
            .catch(function (response) {
            });
    }
    

    const handleChangeMultiSel = (field_name, field_obj, event) => {

        let currval = [];
        var role_details_temp = { ...roleDetails };
        let selected_value = []
        if (event.action === "select-option" && event.option.value === "*") {
            selected_value = field_name === "state" ? [{ label: "Select All", value: "*" }, ...stateList] : [{ label: "Select All", value: "*" }, ...cityList]
            currval = field_name === "state" ? stateList.map(v => v.id) : cityList.map(v => v.id);
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            selected_value = []
        } else if (event.action === "deselect-option" && event.option.value !== "*") {
            selected_value = field_obj.filter((o) => o.value !== "*")
            currval = selected_value.map(v => v.id)
        } else if (field_name === "state" && field_obj.length === stateList.length) {
            currval = stateList.map(v => v.id);
            selected_value = [{ label: "Select All", value: "*" }, ...stateList]
        } else if (field_name === "city" && field_obj.length === cityList.length) {
            currval = cityList.map(v => v.id);
            selected_value = [{ label: "Select All", value: "*" }, ...cityList]
        } else {
            currval = field_obj.map(v => v.id);
            selected_value = field_obj
        }
        role_details_temp[field_name] = currval;
        if (field_name === "state") {
            setStateData(selected_value);
            getCityList(currval, true, role_details_temp)
        }
        if (field_name === "city") {
            setCityData(selected_value);
        }
        setRoleDetails(role_details_temp);
    }

    const role_change_validation = async () => {
        let current_role_id = 0;
        if(props.user_detail && props.user_detail.role_details && props.user_detail.role_details.role){
            current_role_id = props.user_detail.role_details.role;
        }
        let params = {
            user_id: user_detail.user_id,
            role_id: roleDetails.previous_role_id
        }
        let role_status = {}
        role_status.insurance_open_application_status = false;
        role_status.loan_open_application_status = false;
        if(roleChange && (current_role_id === 8 || current_role_id === "8")){
                let pending_in_loanbox = false;
                let pending_in_insurance = false;
                const loan_lead_params = {
                query: 'query{ get_is_all_approved_docs(api_called_by:sfa user_id : '+params.user_id+' role_id:'+params.role_id+') { is_all_doc_approved lead_ids } }'
                };
                
                const pending_loan_lead_res = await MasterService.post('/sfa/user/loan_approved_doc', loan_lead_params);
                if (pending_loan_lead_res && pending_loan_lead_res.data && pending_loan_lead_res.data.status === 200) {
                    if (pending_loan_lead_res && pending_loan_lead_res.data && pending_loan_lead_res.data.data && pending_loan_lead_res.data.data.data) {
                        if(pending_loan_lead_res.data.data.data.get_is_all_approved_docs.is_all_doc_approved === "false"){
                        role_status.insurance_open_application_status = true;
                        pending_in_loanbox = true;
                        // setRoleValidationErrorMsg('There are pending Application for User in loanbox.')
                        }
                    }
                }    

                let insurance_open_application = await DealerService.getUserPendingInsuranceApplication({user_list : [params]});

                if (insurance_open_application && insurance_open_application.data && insurance_open_application.data.status === 200) {
                    if (insurance_open_application && insurance_open_application.data && insurance_open_application.data.data) {
                        for (const item of insurance_open_application.data.data) {
                            for (const [k, v] of Object.entries(item['validation'])) {
                                if (v >= 1) {
                                    role_status.insurance_open_application_status = true;
                                    pending_in_insurance = true;
                                    // setRoleValidationErrorMsg('There are pending Application for User in insurance.')
                                }
                            }
                        }
                    }
                }
                if(pending_in_loanbox && pending_in_insurance){
                    setRoleValidationErrorMsg('There are pending Application for User in loanbox and insurance both.')
                }else if(pending_in_loanbox) {
                    setRoleValidationErrorMsg('There are pending Application for User in loanbox.')
                }else if(pending_in_insurance){
                     setRoleValidationErrorMsg('There are pending Application for User in insurance.')
                }
                return role_status.insurance_open_application_status;
        }

        if(roleChange && (current_role_id === 6 || current_role_id === "6")){

            const loan_lead_params = {
            query: 'query{ get_is_all_approved_docs(api_called_by:sfa user_id : '+params.user_id+' role_id:'+params.role_id+') { is_all_doc_approved lead_ids } }'
            };

            const pending_loan_lead_res = await MasterService.post('/sfa/user/loan_approved_doc', loan_lead_params);
            if (pending_loan_lead_res && pending_loan_lead_res.data && pending_loan_lead_res.data.status === 200) {
                if (pending_loan_lead_res && pending_loan_lead_res.data && pending_loan_lead_res.data.data && pending_loan_lead_res.data.data.data) {
                    if(pending_loan_lead_res.data.data.data.get_is_all_approved_docs.is_all_doc_approved === "false"){
                    role_status.loan_open_application_status = true;
                    setRoleValidationErrorMsg('The Sales Officer needs to get all their pending tasks completed before their role can be updated.')
                    }
                }
            }


            let insurance_open_application = await DealerService.getUserPendingInsuranceApplication({user_list : [params]});

                if (insurance_open_application && insurance_open_application.data && insurance_open_application.data.status === 200) {
                    if (insurance_open_application && insurance_open_application.data && insurance_open_application.data.data) {
                        for (const item of insurance_open_application.data.data) {
                            for (const [k, v] of Object.entries(item['validation'])) {
                                if (v >= 1) {
                                    role_status.loan_open_application_status = true;
                                    setRoleValidationErrorMsg('The Sales Officer needs to get all their pending tasks completed before their role can be updated')
                                }
                            }
                        }
                    }
                }
            return role_status.loan_open_application_status;

        }


    }

    const businessLineChangeValidation = async (roleDetails) => {
        const params = {
            user_id: user_detail.user_id,
            role_id: roleDetails.previous_role_id
        };

        const business_line_status = {
            referral_fee_payout_status: false
        };

        let insuranceStatus = await InsuranceBoxService.getAgencyInsuranceStatus(params);
        if (insuranceStatus?.data?.status === 200 && insuranceStatus?.data?.data?.userLeadStatus) {
            business_line_status.referral_fee_payout_status = insuranceStatus.data.data.userLeadStatus;
        }
        
        let allStatusesTrue = Object.values(business_line_status).every(value => value === true);
        setChangeBusinessLine(allStatusesTrue)
        return allStatusesTrue;
    }


    const handleChange = async (name, selectedOption) => {
        let temp_role_details = { ...roleDetails };
        let tempErrors = { ...errors };
        if (name === 'role') {

            props.getConfigList(selectedOption.id)
            temp_role_details['reporting_role'] = ""
            temp_role_details['reporting_manager'] = ""
            temp_role_details[name] = selectedOption.id
            setRoleDetails(temp_role_details);
            getReportingRole(selectedOption.id)
            setRoleChange(true)
        } else if (name === 'reporting_role') {
            getUsersByRole(selectedOption.id, roleDetails.business_line)
            temp_role_details[name] = selectedOption.id
            setRoleDetails(temp_role_details);
        } else if (name === 'Vertical'){
            temp_role_details['business_vertical_id'] = selectedOption.id
            setBusinessVertialId(selectedOption.id);
            setRoleDetails(temp_role_details);
        } else if (name === 'business_line') {
            temp_role_details['reporting_role'] = ""
            temp_role_details['reporting_manager'] = ""
            temp_role_details[name] = selectedOption.id
            setRoleDetails(temp_role_details)
        } else {
            temp_role_details[name] = selectedOption.id
            setRoleDetails(temp_role_details);
        }
        if(selectedOption.id !=''){
            tempErrors[name]='';
        }
        setErrors(tempErrors);        
    };

    const handleClickCancel = ()=>{
        if(user_detail?.user_id){
            historyLead('/view-user/' + user_detail?.user_id, { replace: true })
        }        
    }

    const handleClickNext = async () => {
        if (isValidate()) {
            setLoading(true)
            let params = {
                user_id: user_detail.user_id,
                role_id: roleDetails.previous_role_id
            }
            let ready_to_role_change = false
            let ready_to_role_change_lead = false
            let ready_to_role_change_reportee = false
            let ready_to_role_change_loan = false
            // let ready_to_role_change_progression = false;

            let pending_loan_res
            let pending_lead_res

            let role_validation_status = await role_change_validation();
            // alert(role_validation_status);

            let user_logout = false
            if(roleDetails.role != roleDetails.previous_role_id || roleDetails.reporting_manager != roleDetails.previous_manager_id){
                user_logout = true
            }

            if (roleChange && is_edit) {
                await MasterService.post('/sfa/user/get_reportees', { userId: [user_detail.user_id] }).then(async function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data.reportee && response.data.data.reportee.length) {
                            toast.error("Please remove the reportees")
                        } else {
                            ready_to_role_change_reportee = true
                        }
                    }
                })
                if (ready_to_role_change_reportee) {
                    ready_to_role_change = true
                }

                if (roleChange) {
                    updateBasicDetail();
                }
                
                if (ready_to_role_change && !role_validation_status) {
                    handleClick(user_logout)
                } else {
                    setRoleChange(false);
                    // props.getConfigList(role_details.previous_role_id)
                }
            } else {
                handleClick(user_logout)
            }
            setLoading(false)

        }
    }

    const handleClick = async (user_logout) => {
        if(is_edit){
            await props.setUserLogout(user_logout)
        }
        await props.handleSave(roleDetails, 'role_details', false)
        if (is_edit) {
            historyLead("/edit-user/basic_details?id=" + user_detail.user_id);
        } else {
            historyLead('/add-user/basic_details');
        }
    }

    const isValidate = () => {
        let tempRoleDetail = { ...roleDetails };
        let formIsValid = true;
        let tempErrors = {};


        if (!tempRoleDetail["state"]) {
            formIsValid = false;
            tempErrors["state"] = 'State is required';
        }
        if (!tempRoleDetail["city"]) {
            formIsValid = false;
            tempErrors["city"] = 'City is required';
        }
        if (!tempRoleDetail["role"]) {
            formIsValid = false;
            tempErrors["role"] = 'Role is required';
        }
        if (!tempRoleDetail["reporting_role"]) {
            formIsValid = false;
            tempErrors["reporting_role"] = 'Reporting Role is required';
        }
        if (!tempRoleDetail["reporting_manager"]) {
            formIsValid = false;
            tempErrors["reporting_manager"] = 'Reporting Manager is required';
        }

        if (!tempRoleDetail["business_line"]) {
            formIsValid = false;
            tempErrors["business_line"] = 'Business Line is required';
        }

        setErrors(tempErrors);
        return formIsValid;
    }

    return (
            <div className='role-details-form p-xxl add-user-detail-filed'>
                {loading ? <Loader /> : null}
                <h2>Role Details</h2>
                <h3>
                    Business Vertical

                </h3>
                <div className='row'>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={Business}
                                placeholder="Business"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={Business.filter(e => e.id === defaultBusiness)}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={Vertical}
                                placeholder="Vertical"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={Vertical.filter(e => e.id === business_vertical_id)}
                                onChange={handleChange.bind(this, 'Vertical')}
                            />
                        </div>
                    </fieldset>
                   
                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <Select 
                                    components={{ ValueContainer: CustomValueContainer }}
                                    // components={{ IndicatorSeparator:() => null }}
                                    isDisabled={!changeBusinessLine}
                                    options={businessLineList}
                                    value={businessLineList.filter((e) => e.id === roleDetails.business_line)}
                                    onChange={handleChange.bind(null, 'business_line')}
                                    placeholder="Business Line*"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    getOptionLabel={({ label }) => label}
                                    getOptionValue={({ id }) => id}
                                />
                            </div>
                            {
                                (errors && errors.business_line) ? <span className="error show">{errors.business_line}</span> : ''
                            }
                        </fieldset>
                    
                </div>

                <h3>
                    Map Region1
                </h3>
                <div className='row'>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={Zone}
                                placeholder="Zone"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={Zone.filter(e => e.id === roleDetails.zone_id)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="multiselect-dropDown col-md-4">
                        <div className="material">

                            {/* <ReactMultiSelectCheckboxes
                                isMulti
                                options={[{ label: "Select All", value: "*" }, ...stateList]}
                                placeholderButtonLabel="State*"
                                iconAfter="false"
                                getDropdownButtonLabel={getDropdownButtonLabel}
                                value={stateData?.length === stateList?.length ? [{ label: "Select All", value: "*" }, ...stateList] : stateData}
                                onChange={handleChangeMultiSel.bind(this, "state")}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                            /> */}
                            <ReactMultiSelectCheckboxes
                                options={[{ label: "Select All", value: "*" }, ...stateList]}
                                value={stateData?.length === stateList?.length ? [{ label: "Select All", value: "*" }, ...stateList] : stateData}
                                onChange={handleChangeMultiSel.bind(this, "state")}
                                name="state"
                                placeholder="State*"
                                isDisabled={false}
                                isClearable={true}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                            />
                            {(stateData && stateData.length > 0) ? <label data-label="State*" className="form-label date-label"></label> : ''}
                            {
                                (errors && errors.state) ? <span className="error show">{errors.state}</span> : ''
                            }
                        </div>
                    </fieldset>
                    <fieldset className="multiselect-dropDown col-md-4">
                        <div className="material">
                            {/* <ReactMultiSelectCheckboxes
                                isMulti
                                options={[{ label: "Select All", value: "*" }, ...cityList]}
                                placeholderButtonLabel="City*"
                                iconAfter="false"
                                getDropdownButtonLabel={getDropdownButtonLabel}
                                value={cityData?.length === cityList?.length ? [{ label: "Select All", value: "*" }, ...cityList] : cityData}
                                onChange={handleChangeMultiSel.bind(this, "city")}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                            /> */}
                            <ReactMultiSelectCheckboxes
                                options={[{ label: "Select All", value: "*" }, ...cityList]}
                                value={cityData?.length === cityList?.length ? [{ label: "Select All", value: "*" }, ...cityList] : cityData}
                                onChange={handleChangeMultiSel.bind(this, "city")}
                                name="city"
                                placeholder="City*"
                                isDisabled={false}
                                isClearable={true}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                            />
                            {(cityData && cityData.length > 0) ? <label data-label="City*" className="form-label date-label"></label> : ''}
                            {
                                (errors && errors.city) ? <span className="error show">{errors.city}</span> : ''
                            }
                        </div>
                    </fieldset>




                </div>

                <h3>
                    Role & Reporting
                </h3>
                <div className='row'>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={roleList}
                                placeholder="Role*"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={roleList.filter(e => e.id === roleDetails.role)}
                                isDisabled={roleDetails.business_line && roleDetails.business_line !== "" ? false : true}
                                onChange={handleChange.bind(this, 'role')}
                            />
                        </div>
                        {
                            (errors && errors.role) ? <span className="error show absolute">{errors.role}</span> : ''
                        }
                        {
                            (role_validation_error_msg) ? <span className="error show absolute">{role_validation_error_msg}</span> : ''
                        }
                    </fieldset>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={reportingRoleList}
                                placeholder="Reporting Role*"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={reportingRoleList.filter(e => e.id === roleDetails.reporting_role)}
                                isDisabled={roleDetails.role && roleDetails.role !== "" ? false : true}
                                onChange={handleChange.bind(this, 'reporting_role')}
                            />
                        </div>
                        {
                            (errors && errors.reporting_role) ? <span className="error show absolute">{errors.reporting_role}</span> : ''
                        }
                    </fieldset>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={reportingManagerList}
                                value={reportingManagerList.filter(({ id }) => id == roleDetails.reporting_manager)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                                onChange={handleChange.bind(this, 'reporting_manager')}
                                placeholder="Reporting Manager*"
                                className="react-select"
                                isDisabled={roleDetails.reporting_role && roleDetails.reporting_role !== "" ? false : true}
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.reporting_manager) ? <span className="error show absolute">{errors.reporting_manager}</span> : ''
                        }
                    </fieldset>

                </div>
                <div className='br-top-flex'>
                    {is_edit ? <div className="t-right">
                        <button className='btn-line m-xs-l' onClick={handleClickCancel.bind(this)}>
                            Cancel
                        </button>
                    </div> : null}
                    <div className="t-right">
                        <button className='btn-primary' onClick={handleClickNext.bind(this)}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
    )
}

RoleDetails.propTypes = {
    business_vertical_id: PropTypes.number, // Assuming business_vertical_id is a number, adjust the type accordingly
    stateList: PropTypes.array.isRequired,
    user_detail: PropTypes.object,
    updateBasicDetail: PropTypes.func,
    // Add other PropTypes for your other props if necessary
};

export default RoleDetails;