import React from 'react';
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';

const CommonTooltip = (props) => {
    const {content} = props;
    return (
        <span className='d-block wrap-cell'>{content.substring(0, 16)}
            <Tooltip
                useContext
                html={(
                    <div className="controlled-example tooltip-main">
                        <div className="tooltip-data">
                            <ul className="user-detail wrap-cell">
                                {content}
                            </ul>
                        </div>
                    </div>
                )}
                className="tootip-outer"
                // open="true"
                theme="light"
                size="regular"
                position="bottom"
                trigger="click"
            >
                <span>...<span className='custom-anchor-tag'>more</span></span>
            </Tooltip>
        </span>
    )
}

export default CommonTooltip;