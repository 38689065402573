
export const DateFormat = (d) => {
    return d.getFullYear() + '-' + ((d.getMonth() < 9) ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + ((d.getDate() < 10) ? '0' + (d.getDate()) : (d.getDate()));
}

export const getFirstDate = (given_date) => {
    var date = given_date ? new Date(given_date) : new Date()
    var firstDate = new Date(date.getFullYear(), date.getMonth(), 1)
    return firstDate
}
export const getbackDateInDays = (days) => {
    var today = new Date();
    var pastDate = new Date();
    pastDate.setDate(today.getDate() - days);
    return pastDate
}

export const getTomorrowDate = (given_date) => {
    var currentDate = given_date ? new Date(given_date) : new Date()
    let nextDate = currentDate.setDate(currentDate.getDate() + 1);
    return nextDate
}

export const getPreviousDate = (given_date) => {
    var currentDate = given_date ? new Date(given_date) : new Date()
    let prevDate = currentDate.setDate(currentDate.getDate() - 1);
    return prevDate
}

export const sort = (array, key) => {
    const sortedList = array.sort((a, b) =>
        a[key].localeCompare(b[key]));
    return sortedList
}