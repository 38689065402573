import React, { useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import ReactPaginate from 'react-paginate';
import Loader from '../common/loader';
import { defaultZone, RoleId } from '../../config/constant';
import InputField from '../common/Input';
import { useNavigate } from 'react-router-dom';
import { Calendra, Calendra2 } from './DatePicker'
import { getFirstDate } from '../../config/helper';
import DateFormat from 'dateformat';
import { CSVLink } from 'react-csv';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const DealerlistingTable = (props) => {
    const [zoneValue, setZone] = useState(defaultZone)
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [dealersData, setDealers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(1)
    const [offset, setOffset] = useState(0)
    const [search_text, setSearchText] = useState('')
    const [fromDate, setFromDate] = useState(getFirstDate(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [businessLine, setBusinessLine] = useState('');
    const historyLead = useNavigate();
    const [csvData, setCsvData] = useState([])
    const csvLink = useRef();

    useEffect(() => {
        setDealers(props.dealersData)
        setPageCount(props.pageCount)
        setCityList(props.cityList)
        setStateList(props.stateList)
    }, [props.dealersData, props.pageCount, props.cityList, props.stateList])

    useEffect(() => {
        setOffset(0)
    }, [props.isPageReset])

    useEffect(() => {
        if (props.isReset) {
            handleReset()
        }
    }, [props.isReset])

    const handleReset = () => {
        setStateList([])
        setCityList([])
        setCity(null)
        setState(null)
        setDealers(null)
        setPageCount(1)
        setOffset(0)
        setSearchText('')
        handleSearch(true)
        setFromDate(getFirstDate(new Date()))
        setToDate(new Date())
        setBusinessLine('')
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage)
        let search_data = {
            search_text: search_text ? search_text : '',
            city_id: city ? [city.id] : '',
            state_id: state ? [state.id] : '',
            fromDate: '',
            toDate: '',
            business_line: businessLine.value,
        }
        props.handleSearchData(search_data)
        props.getDealerNoVisit(selectedPage + 1, search_data)
    };

    const handleChange = (event, name) => {
        console.log("event", event)
        if (name === "state") {
            setState(event)
            setCity(null)
            props.getCityList(event, true)
        }
        if (name === "city") {
            setCity(event)
        }
        if (name === "zone") {
            setZone(event.id)
        }
        if (name === "from_date") {
            setFromDate(event)
        }
        if (name === "to_date") {
            setToDate(event)
            if (new Date(event).getTime() <= new Date(fromDate).getTime()) {
                setFromDate(null)
            }
        } else if (name === 'business_line') {
            console.log("event.value", event.value);
            setBusinessLine(event);
        }
    }

    const handleInputChange = async (target_name, type, event) => {
        if (target_name === "search_text") {
            setSearchText(event.target.value)
        }
    }

    const handleSearch = (is_reset) => {
        setOffset(0)
        let search_data = {
            search_text: search_text ? search_text : '',
            city_id: city ? [city.id] : '',
            state_id: state ? [state.id] : '',
            page: 1,
            fromDate: fromDate,
            toDate: toDate,
            business_line: businessLine.value,
        }
        if (is_reset) {
            search_data = {
                city_id: "",
                page: 1,
                search_text: "",
                state_id: "",
                fromDate: '',
                toDate: '',
                businessLine: ''
            }
        }
        props.handleSearchData(search_data)
        props.getDealerNoVisit(offset + 1, search_data)
    }

    const isDate = (date) => {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    const businessLineList = props.configList.length ? props.configList.map((ele) => {
        return {id: ele.id, label: ele.business_line, value:ele.business_line_id}
    }) : [];

    const downloadData = async () => {
        let csv_data = await props.exportDealerNoVisit()
        // console.log("===csv_data====", csv_data);
        setCsvData(csv_data)
        csvLink.current.link.click();
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="table-heading-filter-no-visit">
                <div className="filter-right-panel">
                    <fieldset className="rangepicker-calendra">
                        <div className="material">
                            <Calendra
                                placeholder={"Date From"}
                                defaultDate={fromDate}
                                endDate={toDate}
                                handleDateChange={(e) => handleChange(e, 'from_date')}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="rangepicker-calendra">
                        <div className="material">
                            <Calendra2
                                placeholder={"To Date"}
                                startDate={fromDate}
                                disabled={true}
                                defaultDate={toDate}
                                endDate={new Date()}
                                handleDateChange={(e) => handleChange(e, 'to_date')}
                            />
                        </div>
                    </fieldset>
                    
                    <fieldset className="single-select">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                value={state}
                                onChange={(e) => handleChange(e, 'state')}
                                options={stateList}
                                placeholder="Select State"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                value={city}
                                isDisabled={state && state.id ? false : true}
                                onChange={(e) => handleChange(e, 'city')}
                                options={cityList}
                                placeholder="Select City"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                value={businessLine}
                                onChange={(e) => handleChange(e, 'business_line')}
                                options={businessLineList}
                                placeholder="Business Line"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="search-bx">
                        <div className="material">
                            <InputField type="text" name="search_text" id="search_text" label="Search Dealer" placeholder="Search Dealer" value={search_text} onChange={handleInputChange.bind(null, 'search_text', 'text')} />
                            <i className="ic-search"></i>
                        </div>
                    </fieldset>
                    <div class="btn-submit-reset m-sm-l">
                        <button className='btn-primary' onClick={() => handleSearch(false)}>Search</button>
                        <button class="btn-reset" onClick={() => handleReset()}>Reset</button>
                    </div>
                </div>
                
            </div>
            <>
            {
                <div className='visit-report-table-heading no-visit-export'>
                    <button className="btn-line">
                        <i class="ic-export m-xs-r"></i>
                        <span onClick={() => downloadData()} >Export Data</span>
                        <CSVLink
                            data={csvData}
                            filename={`dealer-not-visited-list-${Math.round(+new Date() / 1000)}.csv`}
                            data-interception='off'
                            ref={csvLink}
                        >
                        </CSVLink>
                    </button>
                </div>
                }
            </>

            <div className='dealer-listing-table-data data-table dealer-not-visit-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Dealer Details</th>
                            <th>Dealership City/State</th>
                            <th>Mapped Executive</th>
                            <th>Role</th>
                            <th>Business Line</th>
                            <th>Reporting Manager</th>
                            <th>Last Visited Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dealersData?.length > 0 && dealersData.map((dealers, index) => (
                            <tr key={index}>
                                <td className='v-top'>
                                    <span title={dealers.dealer_name} className='d-block'> {dealers.dealer_name?dealers.dealer_name.substring(0,15)+"...":""}</span>
                                    <span>{" (GCD-" + dealers.dealer_code + ")"}</span>
                                </td>
                                <td className='v-top'>
                                    <span className='d-block'> {dealers.city ? dealers.city : '-'}</span>
                                    <span>{dealers.state ? dealers.state : '-'}</span>
                                </td>
                                <td className='map-dealer-column'>

                                    <table className='subtable'>
                                        <tbody>
                                            {dealers.mapped_data?.length > 0 && dealers.mapped_data.map((mapped_detail, mapped_index) => (
                                                <tr key={mapped_index}><td>
                                                    <span className='d-block' title={mapped_detail.mapped_executive}>{(mapped_detail.mapped_executive ? mapped_detail.mapped_executive : "-")}</span>
                                                    <span>{(mapped_detail.mapped_executive_id ? "SFA ID - " + mapped_detail.mapped_executive_id : "-")}</span>
                                                </td></tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table className='subtable'>
                                        <tbody>
                                            {dealers.mapped_data?.length > 0 && dealers.mapped_data.map((mapped_detail, mapped_index) => (
                                                <tr key={mapped_index}><td>{(mapped_detail.role_name ? mapped_detail.role_name : "-")}</td></tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table className='subtable'>
                                        <tbody>
                                            {dealers.mapped_data?.length > 0 && dealers.mapped_data.map((mapped_detail, mapped_index) => (
                                                <tr key={mapped_index}><td>{props.configList.length ? props.configList.find(obj => obj.business_line_id === mapped_detail.business_line).business_line : "-"}</td></tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table className='subtable'>
                                        <tbody>
                                            {dealers.mapped_data?.length > 0 && dealers.mapped_data.map((mapped_detail, mapped_index) => (
                                                <tr key={mapped_index}><td title={(mapped_detail.manager_name ? mapped_detail.manager_name + " (" + RoleId[mapped_detail.manager_role_id] + ")" : "-")}>{(mapped_detail.manager_name ? mapped_detail.manager_name + " (" + RoleId[mapped_detail.manager_role_id] + ")" : "-")}</td></tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>

                                <td className='last_visited_column'>{dealers.last_visited_date && isDate(dealers.last_visited_date) ? DateFormat(new Date(dealers.last_visited_date), 'dd mmm, yyyy') : "-"}</td>
                                <td className='v-top status_text_column'>{dealers.status == "1" ? "Active" : "Inactive"}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <div className='pagination-bx' id="dealerUserList">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        forcePage={offset}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div >
            </div>

        </React.Fragment>
    )
}

export default DealerlistingTable;