import React from 'react';
import { ACCOUNT_NO_VALIDATION, ALPHA_NUMERIC_VALIDATION, EMAIL_VALIDATION, invalidNumberValues, MOBILE_VLIDATION_RULE, NAME_VALIDATION } from '../../config/constant';

class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || "",
      id: props.id || "",
      name: props.name || "",
      type: props.type || "type",
      error: props.error || "",
      label: props.label || "",
      maxLength: props.maxLength || "",
      required: props.required || false
    };
  }

  changeValue = (event) => {
    let { name, value } = event.target;
    if ((name === "mobile_no" && value.length > 12)) {
    }
    else if (value !== "" && (name === "bank_branch_name" || name === "beneficiary_name") && value.match(NAME_VALIDATION) == null) {
    } else if (value !== "" && (name === "employee_id"  || name === "sss_id_number" || name === "tin_id") && value.match(ALPHA_NUMERIC_VALIDATION) == null) {
    } else if (value !== "" && (name === "postal_code" || name === "bank_account_number" || name === "confirm_bank_account_number" || name === "account_number") && !/^[0-9]+$/.test(value)) {
    } else if (value !== "" && (this.props.value && this.props.value.length < value.length) && this.props.maxLength > 0 && value.length > this.props.maxLength && (name === "account_number" || name === "bank_account_number" || name === "confirm_bank_account_number")) {
    } else {
      if (/^[0-9]+$/.test(this.props.min)) value = Math.max(parseFloat(this.props.min), parseFloat(value));
      if (/^[0-9]+$/.test(this.props.max)) value = Math.min(parseFloat(this.props.max), parseFloat(value));
      event.target.value = value;
      this.setState({ value, error: "" });
      if (typeof this.props.onChange === 'function') {
        this.props.onChange(event);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      error: nextProps.error,
      required: nextProps.required,
      value: nextProps.value
    });
  }

  onKeyPress = (event) => {
    this.props.onKeyPress && this.props.onKeyPress(event)
    if (event.which === 13) {
      // console.log('onKeyPress() > event > ENTER CALL');
      // this.setState({ value: this.props.predicted });

    }
  }

  onKeyDown = (event) => {
    if (invalidNumberValues.includes(event.key)) {
      event.preventDefault();
    }
  }

  onBlur = (event) => {
    this.setState({ active: false })
    if (typeof this.props.onBlur == 'function') {
      this.props.onBlur(event)
    }
  }

  onPasteDisallow = (event) => {
    event.preventDefault();
  }
  onCopyDisallow = (event) => {
    event.preventDefault();
  }

  render() {
    const { active, value, error, label, id, type, name, required, maxLength } = this.state;
    const { predicted, locked } = this.props;
    let fieldClassName = `form-field ${(locked ? active : active || value) &&
      "active"} ${locked && !active && "locked"}`;
    if (error) fieldClassName += ` field-error`
    return (
      <>
        {active &&
          value &&
          predicted &&
          predicted.includes(value) && <p className="predicted">{predicted}</p>}
        {/* <label htmlFor={id}>{label}</label> */}
        {/* <label htmlFor={id}>{label} {required && <span className="required" style={{ color: 'red' }}>*</span>}</label> */}
        <input
          id={id}
          className="form-input"
          type={type}
          value={value || ""}
          name={name}
          onPaste={this.props.RestrictOnPaste == 1 ? this.onPasteDisallow : ''}
          onCopy={this.props.RestrictOnCopy == 1 ? this.onCopyDisallow : ''}
          placeholder={this.props.placeholder}
          onChange={this.changeValue}
          disabled={this.props.disabled}
          onKeyPress={this.onKeyPress}
          onKeyDown={type === "number" || name === "bank_account_number" || name === "confirm_bank_account_number" || name === "account_number" || name === "confirm_account_number" ? this.onKeyDown : null}
          //onFocus={() => !locked && this.setState({ active: true })}
          onFocus={this.props.onFocus}
          onBlur={this.onBlur}
          autoComplete="off"
          readOnly={this.props.readOnly}
        />
        {error && <span className="error show">{error}</span>}
      </>
    );
  }
}



export default InputField;

