import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { Tab, Nav } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import { Dropdown } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import DateFormat from 'dateformat';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
// import ReactMultiSelectCheckboxes from '../../library_modules/react-multiselect-checkboxes/lib';
import ReactMultiSelectCheckboxes from "../common/ReactMultiSelectCheckboxes"
import MasterService from './../../services/MasterService';
import InputField from '../common/Input';
import { toast } from 'react-toastify';
import ls from "local-storage";
import { useNavigate } from 'react-router-dom';
import Loader from '../common/loader';
import ChangeMappingPopUp from './ChangeMappingPopUp';
// import Tooltip from 'react-tooltip-lite';
const { ValueContainer, Placeholder } = components;

const UserManagementDetail = (props) => {
  const csvLink = useRef();
  const allUserLink = useRef();
  const activeUserLink = useRef();
  const inActiveUserLink = useRef();
  const rejectedUserLink = useRef();
  const inProcessUserLink = useRef();
  const approvalPendingUserLink = useRef();
  const lessFilterRef = useRef();
  const [pageCount, setPageCount] = useState(0)
  const [offset, setOffset] = useState(0);
  const [userList, setUserList] = useState({});
  const [CityData, setCityData] = useState(ls.get('filter_data').city_list_particular);
  const [RoleData, setRoleData] = useState(ls.get('filter_data').role_list_particular);
  const [StateData, setStateData] = useState(ls.get('filter_data').state_list_particular);
  const [userManagementSearch, setUserManagementSearch] = useState(ls.get('filter_data'));
  const [ReportingRole, SetReportingRole] = useState([]);
  const [AllReportingRole, SetAllReportingRole] = useState([]);
  const [ReportingTo, SetReportingTo] = useState([]);
  const [BusinessLine, SetBusinessLine] = useState([]);
  const [StateList, SetStateList] = useState([]);
  const [CityList, SetCityList] = useState([]);
  //const [dateRange, setDateRange] = useState([null, null]);
  const [dateRange, setDateRange] = useState([(ls.get('filter_data').start_created_date) ? new Date(ls.get('filter_data').start_created_date) : null, (ls.get('filter_data').end_created_date) ? new Date(ls.get('filter_data').end_created_date) : null]);
  const [startDate, endDate] = dateRange;
  const [dateRange1, setDateRange1] = useState([(ls.get('filter_data').start_approved_date) ? new Date(ls.get('filter_data').start_approved_date) : null, (ls.get('filter_data').end_approved_date) ? new Date(ls.get('filter_data').end_approved_date) : null]);
  //const [dateRange1, setDateRange1] = useState([null, null]);
  const [startDate1, endDate1] = dateRange1;
  const [TaggingOption, setTaggingOption] = useState([]);
  const [TaggingList, setTaggingList] = useState([]);
  //console.log(ls.get('current_status'));
  const [currentStatus, setCurrentStatus] = useState((ls.get('current_status')) ? ls.get('current_status') : '');
  const [userCsvData, setUserCsvData] = useState([]);
  const [UserHistoryPopup, SetUserHistoryPopup] = useState(false);
  const [UserRoleHistoryList, setUserRoleHistoryList] = useState([]);
  const [UserAccountHistoryList, setUserAccountHistoryList] = useState([]);
  const [VendorHistoryList, setVendorHistoryList] = useState([]);
  const [ProgressionHistoryList, setProgressionHistoryList] = useState([]);
  const [reportingToHide, setReportingToHide] = useState(1);
  const [sortToggle, setSortToggle] = useState({
    'user_name':false,
    'reporting_role_name': false,
    'reporting_user_name': false,
    'created_date': false,
    'currentToggle': ''
  });
  const [errors, SetErrors] = useState({});
  const [allSelected, setAllSelected] = useState(false);
  const [changeMapping, setChangeMapping] = useState(false);
  const [currentChangeManagerRole, setCurrentChangeManagerRole] = useState(undefined);
  const [isAnyUserSelected, setIsAnyUserSelected] = useState(false);
  const historyLead = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  useEffect(() => {
    getUserList(userManagementSearch, offset, currentStatus, sortToggle);
    getAllRoleList();
    getBusinessLineList();
    getStateList();
    statusHandle();
    getRoleUsersList(userManagementSearch.reporting_role_id);
    getCityList(userManagementSearch.city);
    handleChangeMultiSel("role_id",RoleData);
    handleDpdOptionChange("reportee_type",{ value: 'all_reportee', label: 'All Reportee' });
  }, []);

  useEffect(()=>{
    let atLeadOneSelected = false;
    if(userList?.user_list && userList?.user_list.length){
      let tempAllSelected = true;
      userList.user_list.forEach((ele)=>{
        if(!ele.is_selected && ele.reporting_to_role == currentChangeManagerRole?.reporting_to_role){
          tempAllSelected = false;
        }
        if(ele.is_selected){
          atLeadOneSelected = true;
        }
      })
      setAllSelected(tempAllSelected);
    }else{
      setAllSelected(false);
      setCurrentChangeManagerRole(undefined);
    }

    if(!atLeadOneSelected){
      setAllSelected(false);
      setCurrentChangeManagerRole(undefined);
      setIsAnyUserSelected(false)
    }else{
      setIsAnyUserSelected(true);
    }
  },[userList])

  useEffect(()=>{
    setTaggingList([]);
  },[RoleData])

  const handleSelectAll = ()=>{
    let tempUserList = {...userList};
    let tempCurrentChangeManagerRole = currentChangeManagerRole;
    if(tempUserList?.user_list){
      if(tempCurrentChangeManagerRole === undefined){
        tempCurrentChangeManagerRole = tempUserList.user_list.find(ele => ele.reporting_to_role) || undefined;
      }
      
      if(!allSelected){
        let isAnyOneSelected = false;
        tempUserList.user_list = tempUserList.user_list.map((ele)=>{
          if(tempCurrentChangeManagerRole && ele.reporting_to_role == tempCurrentChangeManagerRole?.reporting_to_role){
            isAnyOneSelected = true;
            return {
              ...ele,
              is_selected:true,
            }
          }else{
            return {
              ...ele,
              is_selected:false,
            }
          }          
        })

        if(!isAnyOneSelected){
          toast.error("Nothing to select as all. Please try with indivdual selection.")
        }

      }else{
        tempUserList.user_list = tempUserList.user_list.map((ele)=>{
          return {
            ...ele,
            is_selected:false,
          }
        })

        tempCurrentChangeManagerRole = undefined;
      }
    }
    setUserList(tempUserList);
    setAllSelected(!allSelected);
    setCurrentChangeManagerRole(tempCurrentChangeManagerRole);
  }

  const handleSelectOne = (k)=>{
    let tempUserList = {...userList};
    let tempCurrentChangeManagerRole = currentChangeManagerRole;
    if(tempCurrentChangeManagerRole){
      if(!tempUserList.user_list[k].is_selected){
        if(tempUserList.user_list[k].reporting_to_role){
          if(tempUserList.user_list[k].reporting_to_role == tempCurrentChangeManagerRole?.reporting_to_role){
            tempUserList.user_list[k].is_selected = true;
          }else{
            toast.error("Please select only users who have the same reporting to role")
          }
        }else{
          toast.error("Please add reporting to role for particular user before this action.")
        }
      }else{
        tempUserList.user_list[k].is_selected = false;
      }      
    }else{
      tempCurrentChangeManagerRole = tempUserList.user_list[k] || undefined;
      if(!tempUserList.user_list[k].is_selected){
        if(tempUserList.user_list[k].reporting_to_role){
          if(tempUserList.user_list[k].reporting_to_role == tempCurrentChangeManagerRole?.reporting_to_role){
            tempUserList.user_list[k].is_selected = true;
          }else{
            toast.error("Please select only users who have the same reporting to role")
          }
        }else{
          toast.error("Please add reporting to role for particular user before this action.")
        }
      }else{
        tempUserList.user_list[k].is_selected = false;
      }
    }    
    setUserList(tempUserList);
    setCurrentChangeManagerRole(tempCurrentChangeManagerRole);
  }

  const hidechangeMappingPopup = ()=>{
    setChangeMapping(false);
    document.body.classList.remove("overflow-hidden");
    getUserList(userManagementSearch, offset, currentStatus, sortToggle);
  }

  const showChangeMappingPopup = ()=>{
    let tempUserList = userList?.user_list?.filter(ele => ele.is_selected) || [];
    if(tempUserList.length){
      setChangeMapping(true);
      document.body.classList.add("overflow-hidden");
    }else{
      toast.error("Please select user.")
    }
    
  }

  const OnboardedFrom = [
    // { value: 'mobile_app', label: 'App' },
    // { value: 'join_us', label: 'Join Us' },
    { value: 'admin', label: 'SFA Backend' },
  ];

  const eSignStatus = [
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' },

  ];
  const reporteeType = [
    { value: 'direct_reportee', label: 'Direct Reportee' },
    { value: 'all_reportee', label: 'All Reportee' },
  ];
  const statusHandle = () => {
    if (currentStatus.toString() == '0') {
      inActiveUserLink.current.click();
    } else if (currentStatus.toString() == '1') {
      activeUserLink.current.click();
    } else if (currentStatus.toString() == '3') {
      rejectedUserLink.current.click();
    } else if (currentStatus.toString() == '4') {
      inProcessUserLink.current.click();
    } else if (currentStatus.toString() == '5') {
      approvalPendingUserLink.current.click();
    } else if (currentStatus.toString() == '') {
      allUserLink.current.click();
    }
  }
  const getTaggingList = async (roleIds) => {
    //setLoading(true); 
    MasterService.post('/sfa/user/tagging_list', { 'role_ids': roleIds })
      .then(function (response) {
        //setLoading(false); 
        if (response.data.status == 200) {
          setTaggingOption(response.data.data);
        }
      })
      .catch(function (response) {
        //setLoading(false); 
      });
  }
  const getUserList = async (userManagementSearchTemp, page, status, sortToggle = {}) => {
    delete (userManagementSearchTemp.status);
    setLoading(true);
    MasterService.post('/sfa/user/get-users-List', { 'sort_toggle': sortToggle, 'status': status, 'page_no': page + 1, ...userManagementSearchTemp })
      .then(function (response) {
        if (response.data.status == 200) {
          setLoading(false);
          setUserList(response.data.data);
          if(response.data.pagination && response.data.pagination.total){
            let tempPageCount = Math.ceil(response.data.pagination.total / 50);
            setPageCount(tempPageCount);
          }else{
            setPageCount(0);
          }          
        }else{
          setLoading(false);
        }
      })
      .catch(function (response) {
        setLoading(false);
      });
  }
  const getBusinessLineList = async () => {
    MasterService.post('/sfa/user/business_line_list', {})
      .then(function (response) {
        if (response.data.status == 200) {
          SetBusinessLine(response.data.data);
        }
      })
      .catch(function (response) {
      });
  }
  const getAllRoleList = async () => {
    MasterService.post('/sfa/user/role_list', {})
      .then(function (response) {
        if (response.data.status == 200) {
          const arr = response.data.data && response.data.data.length > 0 && response.data.data.filter(i => ![6].includes(i.id))
          SetReportingRole(arr);
          SetAllReportingRole(response.data.data);
        }
      })
      .catch(function (response) {
      });
  }
  const getStateList = async () => {
    MasterService.post('/sfa/user/state_list', {})
      .then(function (response) {
        if (response.data.status == 200) {
          SetStateList(response.data.data);
        }
      })
      .catch(function (response) {
      });
  }
  const handleDpdOptionChange = (sname, oValue) => {
    let statusId = oValue.id;
    var userManagementSearchTemp = { ...userManagementSearch };
    if (oValue == null)
      userManagementSearchTemp[sname] = 0;
    if (sname == 'business_line') {
      userManagementSearchTemp[sname] = oValue.business_line_id;
    } else if (sname == 'esign_status' || sname == 'user_source' || sname == 'reportee_type') {
      userManagementSearchTemp[sname] = oValue.value;
    } else {
      userManagementSearchTemp[sname] = statusId;
    }
    setUserManagementSearch(userManagementSearchTemp);
  }
  const handleRoleOptionChange = (sname, oValue) => {
    let role_id = 0;
    if (typeof oValue == 'number') {
      role_id = oValue;
    } else {
      role_id = oValue.id;
    }
    var userManagementSearchTemp = { ...userManagementSearch };
    if (oValue == null)
      userManagementSearchTemp[sname] = 0;
    else
      userManagementSearchTemp[sname] = role_id;
    setUserManagementSearch(userManagementSearchTemp);
    setReportingToHide(0);
    getRoleUsersList(role_id);
  }
  const getRoleUsersList = (role_id) => {
    //setLoading(true); 
    MasterService.post('/sfa/user/get-users', { 'role_id': role_id })
      .then(function (response) {
        //setLoading(false); 
        if (response.data.status == 200) {
          SetReportingTo(response.data.data.user_list);
        }
      })
      .catch(function (response) {
        //setLoading(false); 
      });
  }
  const handleChangeMultiSel = (field_name, field_obj) => {
    let currval = [], branchDataList = [], tagging_list = [];
    var userManagementSearchTemp = { ...userManagementSearch };
    if (field_name === "state") {
      currval = field_obj.map(v => v.id);
      setStateData(field_obj);
      getCityList(currval);
    }
    if (field_name === "city") {
      currval = field_obj.map(v => v.id);
      setCityData(field_obj);
    }
    if (field_name === "role_id") {
      currval = field_obj?.map(v => v.id);
      let taggingListOption = getTaggingList(currval);
      if(taggingListOption && Array.isArray(taggingListOption)){
        setTaggingOption(taggingListOption);
      }      
      setRoleData(field_obj);
    }
    if (field_name === "tagging_id") {
      currval = field_obj?.map(v => v.tagging_id);
      setTaggingList(field_obj);
    }
    userManagementSearchTemp[field_name] = currval;
    setUserManagementSearch(userManagementSearchTemp);
  }
  const getCityList = async (state_ids) => {
    //setLoading(true); 
    SetCityList([]);
    if (state_ids && state_ids.length > 0) {
      MasterService.post('/sfa/user/city_list', { "state_ids": state_ids })
        .then(function (response) {
          //setLoading(false); 
          if (response.data.status == 200) {
            SetCityList(response.data.data);
          }
        })
        .catch(function (response) {
          //setLoading(false); 
        });
    }
  }
  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.length === 0) {
      return `${placeholderButtonLabel}`;
    } else if (value && value.length) {
      return `${value.length} selected`;
    }
    else
      return `${placeholderButtonLabel}`;
  }
  const resetMoreFilter = (event)=>{
    resetFilterForm(event);
    lessFilterRef.current.click();
  }

  const resetLessFilter = (event)=>{
    resetFilterForm(event);
  }
  const resetFilterForm = event => {
    event.preventDefault();
    setDateRange([null, null]);
    setDateRange1([null, null]);
    setRoleData([]);
    setTaggingList([]);
    setStateData([]);
    setCityData([]);
    setUserManagementSearch({});
    setReportingToHide(1);
    SetErrors({});
    setCurrentChangeManagerRole(undefined);
    setAllSelected(false);
    setTaggingOption([]);
    let tempUserList = {...userList};
    if(tempUserList?.user_list?.length){
      tempUserList.user_list = tempUserList.user_list.map((ele)=>{
        return {
          ...ele,
          is_selected:false,
        }
      })
    }
    //setUserList(tempUserList);
    getUserList({}, 0, currentStatus, {});
  }
  const handleSubmitWithoutMoreFilter = (event)=>{
    submitFilterForm(event);
  }
  const handleSubmitWithMoreFilter = (event)=>{
    submitFilterForm(event);
    lessFilterRef.current.click();
  }
  const submitFilterForm = event => {
    event.preventDefault();
    setOffset(0);
    if (validateForm()) {
      getUserList(userManagementSearch, 0, currentStatus, sortToggle);
    }
  }
  const validateForm = () => {
    var userManagementSearchTemp = { ...userManagementSearch };
    let formIsValid = true;
    let tempErrors = {};
    if (userManagementSearchTemp["reporting_role_id"]) {
      if (!userManagementSearchTemp["reporting_to"]) {
        tempErrors["reporting_to"] = 'Please apply the filter';
        formIsValid = false;
      }
    }
    SetErrors(tempErrors);
    return formIsValid;
  }
  const handledate = (datefield, date) => {
    var userManagementSearchTemp = { ...userManagementSearch };
    if (datefield == 'created_date') {
      setDateRange(date);
      if (date != null) {
        if (date && date.length > 0) {
          if (date[0]) {
            userManagementSearchTemp['start_created_date'] = new Date(date[0]);
          }
          if (date[1]) {
            userManagementSearchTemp['end_created_date'] = new Date(date[1]);
          }
        }
      }
    } else if (datefield == 'approved_date') {
      setDateRange1(date);
      if (date != null) {
        if (date && date.length > 0) {
          if (date[0]) {
            userManagementSearchTemp['start_approved_date'] = new Date(date[0]);
          }
          if (date[1]) {
            userManagementSearchTemp['end_approved_date'] = new Date(date[1]);
          }
        }
      }
    }
    setUserManagementSearch(userManagementSearchTemp);
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    var userManagementSearchTemp = { ...userManagementSearch };
    setUserManagementSearch(userManagementSearchTemp);
    setOffset(selectedPage);
    getUserList(userManagementSearchTemp, selectedPage, currentStatus, sortToggle);
    setAllSelected(false);
  };
  const tabStatusFilterForm = status_id => {
    setOffset(0);
    setCurrentStatus(status_id);
    var userManagementSearchTemp = { ...userManagementSearch };
    userManagementSearchTemp.status = status_id;
    setUserManagementSearch(userManagementSearchTemp);
    getUserList(userManagementSearchTemp, 0, status_id, sortToggle);

  };
  const getClassName = (statusId) => {
    let statusClass = '';
    if (statusId == 0) {
      statusClass = 'status-inactive';
    } else if (statusId == 1) {
      statusClass = 'status-active';
    } else if (statusId == 2) {
      statusClass = 'status-inactive';
    } else if (statusId == 3) {
      statusClass = 'status-inprocess';
    } else if (statusId == 4) {
      statusClass = 'status-inprocess';
    }
    return statusClass;
  }
  const downloadUserData = async () => {
    setLoading(true);
    await MasterService.post('/sfa/user/download_user_list', { 'status': currentStatus, ...userManagementSearch })
      .then(function (response) {
        setLoading(false);
        if (response.data.status == 200) {
          let tempmMappingCsvData = (response.data && response.data.data.user_list) ? response.data.data.user_list : [];
          setUserCsvData(tempmMappingCsvData);
          csvLink.current.link.click();
        }
      })
      .catch(function (response) {
        setLoading(false);
      });


  }
  const handleChangetext = event => {
    var userManagementSearchTemp = { ...userManagementSearch };
    userManagementSearchTemp[event.target.name] = event.target.value
      .replace(/[^\w\s@\.]/gi, "")
      .replace("_", "");
    setUserManagementSearch(userManagementSearchTemp);
    getUserList(userManagementSearchTemp, 0, currentStatus, sortToggle);
  }
  const showModalUserHistoryPopup = async (userId) => {
    setLoading(true); 
    await MasterService.post('/sfa/user/user_history_list', { 'user_id': userId })
      .then(function (response) {
        setLoading(false); 
        if (response.data.status == 200) {
          let tempRoleHistoryList = (response.data && response.data.data && response.data.data.role_history) ? response.data.data.role_history : [];
          let tempAccountHistoryList = (response.data && response.data.data && response.data.data.account_history) ? response.data.data.account_history : [];
          let tempVendorHistoryList = (response.data && response.data.data && response.data.data.vendor_history) ? response.data.data.vendor_history : [];
          let tempProgressionHistoryList = (response.data && response.data.data && response.data.data.progression_history) ? response.data.data.progression_history : [];
          setUserRoleHistoryList(tempRoleHistoryList);
          setUserAccountHistoryList(tempAccountHistoryList);
          setVendorHistoryList(tempVendorHistoryList);
          setProgressionHistoryList(tempProgressionHistoryList)
          SetUserHistoryPopup(true);
          document.body.classList.add("overflow-hidden");
        }
      })
      .catch(function (response) {
        setLoading(false); 
      });

  }
  const hideModalUserHistoryPopup = () => {
    SetUserHistoryPopup(false);
    document.body.classList.remove("overflow-hidden");
  }
  const forceLogout = async (userId) => {
    setLoading(true); 
    await MasterService.post('/sfa/user/force_user_logout', { 'user_id': userId })
      .then(function (response) {
        setLoading(false); 
        if (response.data.status == 200) {
          toast.success(response.data.message);
          var userManagementSearchTemp = { ...userManagementSearch };
          getUserList(userManagementSearchTemp, offset, currentStatus, sortToggle);
        }
      })
      .catch(function (response) {
        setLoading(false); 
      });
  }



  const handleView = async (user_id) => {
    historyLead('/view-user/' + user_id)
  }
  const toggleSort = (currentSortKey) => {
    let tempSortToggle = { ...sortToggle };
    tempSortToggle.currentToggle = currentSortKey;
    if (currentSortKey == 'user_name') {
      tempSortToggle.user_name = !tempSortToggle.user_name;
      tempSortToggle.reporting_role_name = false;
      tempSortToggle.reporting_user_name = false;
      tempSortToggle.created_date = false;
    }else if (currentSortKey == 'reporting_role_name') {
      tempSortToggle.reporting_role_name = !tempSortToggle.reporting_role_name;
      tempSortToggle.user_name = false;
      tempSortToggle.reporting_user_name = false;
      tempSortToggle.created_date = false;

    } else if (currentSortKey == 'reporting_user_name') {
      tempSortToggle.reporting_user_name = !tempSortToggle.reporting_user_name;
      tempSortToggle.user_name = false;
      tempSortToggle.reporting_role_name = false;
      tempSortToggle.created_date = false;
    } else if (currentSortKey == 'created_date') {
      tempSortToggle.created_date = !tempSortToggle.created_date;
      tempSortToggle.user_name = false;
      tempSortToggle.reporting_role_name = false;
      tempSortToggle.reporting_user_name = false;
    }
    setSortToggle(tempSortToggle);
    getUserList(userManagementSearch, offset, currentStatus, tempSortToggle);
  }
  const handleAdd = async () => {
    historyLead('/add-user/role_details')
  }

  const handleDropdown = (isOpen, event) => {
   if (!validateForm() && isOpen === false) {
    setShowDropdown(true);
  } else {
    setShowDropdown(isOpen);
  }
  }
  return (
    <React.Fragment>
     {loading ? <Loader/>: null }    
      <div className="container-fluid">
        <div className="top-heading d-flex">
          <h1>User Management</h1>
          <div className="right-btn">
            {/* <button className="btn-line m-xs-l" disabled> <i className="ic-upload"></i> Bulk Upload</button> */}
            <button onClick={() => handleAdd()} className="btn-line m-xs-l">Add user</button>
          </div>
        </div>
        <div className="loan-filter-bg">
          <div className="top-filter-bg user-management-fiter">
              <form>
                <div className="lead-list-filter">

                  <div className="filter-filed-form">

                    <fieldset className="rangepicker-calendra">
                      <div className="material">
                        <div className="datepicker">
                          <DatePicker
                            name="created_date"
                            id="created_date"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="yyyy-MM-dd"
                            onChange={handledate.bind(this, 'created_date')}
                            isClearable={false}
                            placeholderText={(!startDate)?"Created Date":''}
                            autoComplete="off"
                            maxDate={new Date()}
                          />
                          {(startDate)?<label data-label="Created Date" className="form-label date-label"></label>:''}
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="rangepicker-calendra">
                      <div className="material">
                        <div className="datepicker">
                          <DatePicker
                            name="approved_date"
                            id="approved_date"
                            selectsRange={true}
                            startDate={startDate1}
                            endDate={endDate1}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="yyyy-MM-dd"
                            onChange={handledate.bind(this, 'approved_date')}
                            isClearable={false}
                            placeholderText={(!startDate1)?"Approval Date":''}
                            autoComplete="off"
                            maxDate={new Date()}
                          />
                          {(startDate1)?<label data-label="Approval Date" className="form-label date-label"></label>:''}
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="single-select">
                      <div className="material">
                        <Select 
                          components={{ ValueContainer: CustomValueContainer }}
                          // components={{ IndicatorSeparator:() => null }}
                          id="user_source"
                          name="user_source"
                          onChange={handleDpdOptionChange.bind(this, 'user_source')}
                          value={OnboardedFrom.filter(({ value }) => value === userManagementSearch.user_source)}
                          options={OnboardedFrom}
                          placeholder="Onboarded From"
                          className="react-select cursor-pointer"
                          classNamePrefix="react-select"
                          isSearchable={false}
                        />
                      </div>
                    </fieldset>
                    <fieldset className="multiselect-dropDown width-14-percent">
                      <div className="material">
                        {/* <ReactMultiSelectCheckboxes
                          isMulti
                          options={AllReportingRole}
                          placeholderButtonLabel="Role"
                          placeholder="Role"
                          iconAfter="false"
                          className="react-select"
                          classNamePrefix="react-select"
                          getDropdownButtonLabel={getDropdownButtonLabel}
                          value={RoleData}
                          onChange={handleChangeMultiSel.bind(this, "role_id")}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        /> */}

                        <ReactMultiSelectCheckboxes
                          options={AllReportingRole}
                          value={RoleData}
                          onChange={handleChangeMultiSel.bind(this, "role_id")}
                          name="role"
                          placeholder="Role"
                          isDisabled={false}
                          isClearable={true}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                        {(RoleData && RoleData.length >0)?<label data-label="Role" className="form-label date-label"></label>:''}
                      </div>
                    </fieldset>

                    {/* <fieldset className="single-select">
                      <div className="material">
                        <Select 
                          components={{ ValueContainer: CustomValueContainer }}
                          // components={{ IndicatorSeparator:() => null }}
                          id="esign_status"
                          name="esign_status"
                          onChange={handleDpdOptionChange.bind(this, 'esign_status')}
                          options={eSignStatus}
                          value={eSignStatus.filter(({ value }) => value === userManagementSearch.esign_status)}
                          placeholder="eSign Status"
                          className="react-select cursor-pointer"
                          classNamePrefix="react-select"
                          isSearchable={false}
                        />
                      </div>
                    </fieldset> */}
                    <div className="more-filter">
                      <div className="filter-option">
                        <Dropdown show={showDropdown} onToggle={handleDropdown} >
                          <div className="text-btn d-flex">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">

                              <span className="more-filter-txt cursor-pointer">More Filters</span>
                              <span ref={lessFilterRef} className="leass-filter-txt cursor-pointer">Less Filters</span>
                            </Dropdown.Toggle>

                            <div className="btn-submit-reset top-btn-none">
                              <button onClick={handleSubmitWithoutMoreFilter} type='submit' className="btn-primary">Search</button>
                              <button onClick={resetLessFilter} className="btn-reset cursor-pointer">Reset</button>
                            </div>
                          </div>
                          <Dropdown.Menu>
                            <div className="more-filter-option">
                              <h3>More Filters</h3>
                              <div className="filter-form">



                                <fieldset className="single-select">
                                  <div className="material">
                                    <Select 
                                      components={{ ValueContainer: CustomValueContainer }}
                                      // components={{ IndicatorSeparator:() => null }}
                                      id="reporting_role_id"
                                      name="reporting_role_id"
                                      onChange={handleRoleOptionChange.bind(this, 'reporting_role_id')}
                                      options={ReportingRole.filter(({ id }) => id !== 8)}
                                      value={ReportingRole.filter(({ id }) => id === userManagementSearch.reporting_role_id)}
                                      getOptionLabel={({ name }) => name}
                                      getOptionValue={({ id }) => id}
                                      placeholder="Reporting to Role"
                                      className="react-select"
                                      classNamePrefix="react-select"
                                      isSearchable={false}
                                    />
                                  </div>
                                </fieldset>

                                <fieldset className="single-select">
                                  <div className="material">
                                    <Select 
                                      components={{ ValueContainer: CustomValueContainer }}
                                      // components={{ IndicatorSeparator:() => null }}
                                      id="reporting_to"
                                      name="reporting_to"
                                      onChange={handleDpdOptionChange.bind(this, 'reporting_to')}
                                      options={ReportingTo}
                                      value={ReportingTo.filter(({ id }) => id === userManagementSearch.reporting_to)}
                                      getOptionLabel={({ name }) => name}
                                      getOptionValue={({ id }) => id}
                                      placeholder="ReportingTo"
                                      className="react-select"
                                      classNamePrefix="react-select"
                                      isSearchable={true}
                                      isDisabled={(reportingToHide == 1) ? true : false}
                                    />
                                    {
                                      (errors && errors.reporting_to) ? <span className="error show">{errors.reporting_to}</span> : ''
                                    }
                                  </div>
                                </fieldset>
                                {/* <fieldset className="single-select">
                                  <div className="material">
                                    <Select 
                                      components={{ ValueContainer: CustomValueContainer }}
                                      // components={{ IndicatorSeparator:() => null }}
                                      id="reportee_type"
                                      name="reportee_type"
                                      onChange={handleDpdOptionChange.bind(this, 'reportee_type')}
                                      options={reporteeType}
                                      value={reporteeType.filter(({ value }) => value === userManagementSearch.reportee_type)}
                                      placeholder="Reportee Type"
                                      className="react-select"
                                      classNamePrefix="react-select"
                                      isSearchable={false}
                                      isDisabled={(userManagementSearch.reporting_role_id && userManagementSearch.reporting_to) ? false : true}
                                    />
                                  </div>
                                </fieldset> */}
                                <fieldset className="multiselect-dropDown">
                                  <div className="material">
                                    {/* <ReactMultiSelectCheckboxes
                                      isMulti
                                      options={TaggingOption}
                                      placeholderButtonLabel="Tagging"                                     
                                      iconAfter="false"
                                      className="react-select"
                                      classNamePrefix="react-select"
                                      getDropdownButtonLabel={getDropdownButtonLabel}
                                      value={TaggingList}
                                      isSearchable={false}
                                      onChange={handleChangeMultiSel.bind(this, "tagging_id")}
                                      getOptionLabel={({ tagging_name }) => tagging_name}
                                      getOptionValue={({ tagging_id }) => tagging_id}
                                      // isDisabled = {RoleData?.map(ele=>ele.id).includes(Role['Sales Officer']) ? false : true}
                                    /> */}
                                    <ReactMultiSelectCheckboxes
                                      options={TaggingOption}
                                      value={TaggingList}
                                      onChange={handleChangeMultiSel.bind(this, "tagging_id")}
                                      name="tagging"
                                      placeholder="Tagging"
                                      isDisabled={false}
                                      isClearable={true}
                                      getOptionLabel={({ tagging_name }) => tagging_name}
                                      getOptionValue={({ tagging_id }) => tagging_id}
                                    />

                                    {(TaggingList && TaggingList.length >0)?<label data-label="Tagging" className="form-label date-label"></label>:''}
                                  </div>
                                </fieldset>
                                <fieldset class="multiselect-dropDown">
                                  <div class="material">
                                    {/* <ReactMultiSelectCheckboxes
                                      isMulti
                                      options={StateList}
                                      placeholderButtonLabel="State"
                                      iconAfter="false"
                                      getDropdownButtonLabel={getDropdownButtonLabel}
                                      value={StateData}
                                      onChange={handleChangeMultiSel.bind(this, "state")}
                                      getOptionLabel={({ state_name }) => state_name}
                                      getOptionValue={({ id }) => id}
                                    /> */}
                                    <ReactMultiSelectCheckboxes
                                      options={StateList}
                                      value={StateData}
                                      onChange={handleChangeMultiSel.bind(this, "state")}
                                      name="state"
                                      placeholder="State"
                                      isDisabled={false}
                                      isClearable={true}
                                      getOptionLabel={({ state_name }) => state_name}
                                      getOptionValue={({ id }) => id}
                                    />
                                    {(StateData && StateData.length >0)?<label data-label="State" className="form-label date-label"></label>:''}
                                  </div>
                                </fieldset>

                                <fieldset class="multiselect-dropDown">
                                  <div class="material">
                                    {/* <ReactMultiSelectCheckboxes
                                      isMulti
                                      options={CityList}
                                      placeholderButtonLabel="City"
                                      iconAfter="false"
                                      getDropdownButtonLabel={getDropdownButtonLabel}
                                      value={CityData}
                                      onChange={handleChangeMultiSel.bind(this, "city")}
                                      getOptionLabel={({ city_name }) => city_name}
                                      getOptionValue={({ id }) => id}
                                    /> */}
                                    <ReactMultiSelectCheckboxes
                                      options={CityList}
                                      value={CityData}
                                      onChange={handleChangeMultiSel.bind(this, "city")}
                                      name="city"
                                      placeholder="City"
                                      isDisabled={false}
                                      isClearable={true}
                                      getOptionLabel={({ city_name }) => city_name}
                                      getOptionValue={({ id }) => id}
                                    />
                                    {(CityData && CityData.length >0)?<label data-label="City" className="form-label date-label"></label>:''}
                                  </div>
                                </fieldset>


                                <fieldset className="single-select">
                                  <div className="material">
                                    <Select 
                                      components={{ ValueContainer: CustomValueContainer }}
                                      // components={{ IndicatorSeparator:() => null }}
                                      id="business_line"
                                      name="business_line"
                                      onChange={handleDpdOptionChange.bind(this, 'business_line')}
                                      options={BusinessLine}
                                      value={BusinessLine.filter(({ business_line_id }) => business_line_id === userManagementSearch.business_line)}
                                      getOptionLabel={({ business_line }) => business_line}
                                      getOptionValue={({ business_line_id }) => business_line_id}
                                      placeholder="Sales Team/Business Line"
                                      className="react-select"
                                      isSearchable={false}
                                      classNamePrefix="react-select"
                                    />
                                  </div>
                                </fieldset>                                                                
                              </div>
                              <div className="btn-submit-reset more-filter-btn p-sm-t">
                                <button onClick={handleSubmitWithMoreFilter}  type='submit' className="btn-primary">Search</button>
                                <button onClick={resetMoreFilter} className="btn-reset cursor-pointer">Reset</button>
                              </div>

                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
          </div>
          <div className="user-action-btn">
            <h2>Users</h2>
            <div className="right-btn">
              {isAnyUserSelected ? <button className="btn-line" onClick={showChangeMappingPopup} disabled={['1'].includes(currentStatus) ? false : true}>Change Reporting</button> : null}
              <button className="btn-line">
                <i class="ic-export m-xs-r"></i>
                <span onClick={downloadUserData}>Export Data</span>
                <CSVLink
                  data={userCsvData}
                  filename={`user-list-${Math.round(+new Date() / 1000)}.csv`}
                  data-interception='off'
                  ref={csvLink}
                >
                </CSVLink>
              </button>
            </div>
          </div>
          <div className="user-management-detail-tab-outer">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="user-managemnt-detail-tabs">
                <Nav variant="pills" className="flex-column tab-line">
                  <div className="tab-list">
                    <Nav.Item onClick={() => tabStatusFilterForm("")} ref={allUserLink}>
                      <Nav.Link eventKey="first">All Users ({userList.tab_count_data && userList.tab_count_data.total_user})  </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => tabStatusFilterForm("1")}>
                      <Nav.Link eventKey="second" ref={activeUserLink}> Active Users ({userList.tab_count_data && userList.tab_count_data.active_user_count})</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item onClick={() => tabStatusFilterForm("5")}>
                      <Nav.Link eventKey="sixth" ref={approvalPendingUserLink}>Approval Pending Users ({userList.tab_count_data && userList.tab_count_data.approval_pending_user_count})</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item onClick={() => tabStatusFilterForm("4")}>
                      <Nav.Link eventKey="fourth" ref={inProcessUserLink}>In Progress Users ({userList.tab_count_data && userList.tab_count_data.inprocess_user_count})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => tabStatusFilterForm("0")}>
                      <Nav.Link eventKey="third" ref={inActiveUserLink}>Inactive Users ({userList.tab_count_data && userList.tab_count_data.inactive_user_count})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => tabStatusFilterForm("3")}>
                      <Nav.Link eventKey="fifth" ref={rejectedUserLink}>Rejected Users ({userList.tab_count_data && userList.tab_count_data.rejected_user_count})</Nav.Link>
                    </Nav.Item>
                  </div>
                </Nav>
                <fieldset className="search-bx">
                  <div class="material">
                    <InputField
                      id="search_by"
                      type="text"
                      placeholder="Search By Name,SFA ID,Mobile No,Email"
                      name="search_by"
                      autoComplete="off"
                      value={
                        userManagementSearch &&
                          userManagementSearch.search_by
                          ? userManagementSearch.search_by
                          : ""
                      }
                      label="Search"
                      onChange={handleChangetext}
                    />
                    <i className="ic-search"></i>
                  </div>
                </fieldset>
              </div>
              <div className='user-managemnt-detail-table data-table'>
                <table>
                  <thead>
                    <tr>
                      {['1'].includes(currentStatus) ? <th>
                                  <li class="custom-control custom-checkbox">
                                    <input id="sli0" type="checkbox" class="custom-control-input" checked={allSelected} onChange={handleSelectAll}/>
                                    <label for="sli0" class="custom-control-label">
                                    </label>
                                </li>
                                </th>:<th style={{width:'2px'}}></th>}                      
                      <th>Name<span onClick={() => toggleSort('user_name')} title="" className="tooltio-ic"><i className={(sortToggle.user_name)?'sorting_asc':'sorting_desc'}></i></span></th>
                      <th>SFA ID</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>User Role</th>
                      <th>Reporting to Role<span onClick={() => toggleSort('reporting_role_name')} title="" className="tooltio-ic"><i className={(sortToggle.reporting_role_name)?'sorting_asc':'sorting_desc'}></i></span></th>
                      <th>Reporting to<span onClick={() => toggleSort('reporting_user_name')} title="" className="tooltio-ic"><i className={(sortToggle.reporting_user_name)?'sorting_asc':'sorting_desc'}></i></span></th>
                      <th>Status</th>
                      <th>Created Date<span onClick={() => toggleSort('created_date')} title="" className="tooltio-ic"><i className={(sortToggle.created_date)?'sorting_asc':'sorting_desc'}></i></span></th>
                      <th>Business Line</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList && userList.user_list && userList.user_list.length == 0 ? <tr><td align="center" colSpan="11"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                    {
                      userList && userList.user_list && userList.user_list.length != 0 && userList.user_list.map((userdata, k) => (
                        <tr key={k} >
                          {['1'].includes(currentStatus) ? <td>
                                   <li class="custom-control custom-checkbox">
                                    <input id="sli0" type="checkbox" class="custom-control-input" checked={userdata.is_selected} onChange={()=>handleSelectOne(k)}/>
                                    <label for="sli0" class="custom-control-label">
                                    </label>
                                </li>
                                </td> : <td style={{width:'2px'}}></td>}                          
                          <td>{userdata.name}</td>
                          <td>{userdata.userId}</td>
                          <td>{userdata.email}</td>
                          <td>{userdata.mobile}</td>
                          <td>{userdata.role_name}</td>
                          <td>{userdata.reporting_to_role}</td>
                          <td>{userdata.reporting_to}</td>
                          <td>
                            <span className={getClassName(userdata.status)}>{userdata.status_name}</span>
                          </td>
                          <td>{DateFormat(new Date(userdata.created_date), 'dd mmm, yyyy')}</td>
                          <td>{userdata.business_line}</td>
                          <td>
                            <ul class="action-btn">
                              <li>
                                <a class="btn btn-default" onClick={() => handleView(userdata.userId)}>
                                  <i class="ic-remove_red_eyevisibility"></i>
                                </a>
                              </li>
                              <li onClick={() => showModalUserHistoryPopup(userdata.userId)}>
                                <i class="ic-Call-history"></i>
                              </li>

                              {
                                (userdata.device_id && userdata.device_id != '') ?

                                  <a target="_self" href="#" className='anchor-txt' onClick={() => forceLogout(userdata.userId)}>Logout</a> : 'Logged Out'
                              }

                            </ul>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                <div className='pagination-bx' id="dealerList">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    forcePage={offset}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                </div >
              </div>
            </Tab.Container>
          </div>
        </div>
        <div className="model-popup-outer role-history-popup">
          <Modal show={UserHistoryPopup} handleClose={hideModalUserHistoryPopup} >
            <div className="modal-header">
              <h2>User Timeline</h2>
            </div>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                <div className="tab-list">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Role History</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Account History</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                      <Nav.Link eventKey="third">Vendor History</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="fourth">Progression History</Nav.Link>
                  </Nav.Item> */}
                </div>
              </Nav>
              <div className='modal-body' >
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className='data-table'>
                      <table>
                        <thead>
                          <tr>
                            <th>Created On</th>
                            <th>Role</th>
                            <th>Tagging</th>
                            <th>Business Line</th>
                            <th>Status</th>
                            <th>Created By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {UserRoleHistoryList && UserRoleHistoryList.length == 0 ? <tr><td align="center" colSpan="8"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                          {
                            UserRoleHistoryList && UserRoleHistoryList.length != 0 && UserRoleHistoryList.map((userhistorydata, k) => (
                              <tr key={k} >
                                <td><span>{DateFormat(new Date(userhistorydata.created_date), 'dd mmm, yyyy')}</span></td>
                                <td>{userhistorydata.role_name}</td>
                                <td>{userhistorydata.tagging_name}</td>
                                <td>{userhistorydata.business_line}</td>
                                <td>{userhistorydata.user_status_name}</td>
                                <td>{(userhistorydata.action_via == 'admin') ? userhistorydata.display_name : userhistorydata.created_by}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>

                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className='data-table'>
                      <table>
                        <thead>
                          <tr>
                            <th>Created On</th>
                            <th>Bank Name</th>
                            <th>Bank Branch</th>
                            <th>Account Number</th>
                            <th>Beneficiary Name</th>
                            {/* <th>NPWP</th> */}
                            <th>Created By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {UserAccountHistoryList && UserAccountHistoryList.length == 0 ? <tr><td align="center" colSpan="6"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                          {
                            UserAccountHistoryList && UserAccountHistoryList.length != 0 && UserAccountHistoryList.map((useraccountdata, k) => (
                              <tr key={k} >
                                <td><span>{DateFormat(new Date(useraccountdata.created_date), 'dd mmm, yyyy')}</span></td>
                                <td>{useraccountdata.bank_name}</td>
                                <td>{useraccountdata.branch_name}</td>
                                <td>{useraccountdata.account_number}</td>
                                <td>{useraccountdata.beneficiary_name}</td>
                                {/* <td>{useraccountdata.npwp}</td> */}
                                <td>{(useraccountdata.action_via == 'admin') ? useraccountdata.display_name : useraccountdata.created_by}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                                        <div className='data-table'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Created On</th>
                                                        <th>Type</th>
                                                        <th>Status</th>
                                                        <th>Vendor ID</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {VendorHistoryList && VendorHistoryList.length == 0 ? <tr><td align="center" colSpan="4"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                    {
                                                        VendorHistoryList && VendorHistoryList.length != 0 && VendorHistoryList.map((uservendordata, k) => (
                                                            <tr key={k} >
                                                                <td><span>{DateFormat(new Date(uservendordata.created_date), 'dd mmm, yyyy')}</span></td>
                                                                <td>{uservendordata.show_type}</td>
                                                                <td>{uservendordata.show_response_status}</td>
                                                                <td>{uservendordata.vendor_id}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <div className='data-table'>
                      <table>
                        <thead>
                          <tr>
                            <th>Date & Time</th>
                            {/* <th>Current Role/Tagging</th> */}
                            <th>Recommended Role/Tagging</th>
                            <th>Recommended By</th>
                            <th>Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ProgressionHistoryList && ProgressionHistoryList.length == 0 ? <tr><td align="center" colSpan="4"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                          {
                            ProgressionHistoryList && ProgressionHistoryList.length != 0 && ProgressionHistoryList.map((userprogressiondata, k) => (
                              <tr key={k} >
                                <td><span>{DateFormat(new Date(userprogressiondata.created_date), 'dd mmm, yyyy h:MM TT')}</span></td>
                                {/* <td>{(userprogressiondata.current_role?userprogressiondata.current_role:"")+"/"+(userprogressiondata.current_tagging?userprogressiondata.current_tagging:"")}</td> */}
                                <td>{(userprogressiondata.recommended_role?userprogressiondata.recommended_role:"")+"/"+(userprogressiondata.recommended_tagging?userprogressiondata.recommended_tagging:"")}</td>
                                <td style={{textTransform:"capitalize"}}>{userprogressiondata.recommended_by?userprogressiondata.recommended_by:""}</td>
                                <td>{userprogressiondata.comment ? /*<Tooltip
                                  content={(
                                    <div className="controlled-example tooltip-main">
                                      <div className="tooltip-data">
                                        <h3 className="subheading">{userprogressiondata.comment}</h3>
                                      </div>
                                    </div>
                                  )}
                                  tagName="span"
                                  direction="down"
                                  className="tootip-outer-refinance"
                                  forceDirection
                                >
                                  <span>{(userprogressiondata.comment) ? (userprogressiondata.comment.length > 10) ? userprogressiondata.comment.substr(0, 10)+"..." : userprogressiondata.comment : ''}</span>
                                </Tooltip>*/ "" : ""}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>

            </Tab.Container>
          </Modal>
          <ChangeMappingModal show={changeMapping} handleClose={hidechangeMappingPopup} >
            <div className="modal-header">
              <h2>Change Mapping</h2>
            </div>
              {changeMapping ? <ChangeMappingPopUp {...{ReportingRole, ReportingTo, userList, currentChangeManagerRole}}/> : null}
              
          </ChangeMappingModal>
        </div>
      </div>
    </React.Fragment>
  )
}
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className='modal-main'>
        {children}
        <button
          onClick={handleClose}
          className="close_icn"
        >
          <i className="ic-clearclose"></i>
        </button>
      </section>
    </div>
  );
};

const ChangeMappingModal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className='modal-main' style = {{width:"650px", height:"300px"}}>
        {children}
        <button
          onClick={handleClose}
          className="close_icn"
        >
          <i className="ic-clearclose"></i>
        </button>
      </section>
    </div>
  );
};

export default UserManagementDetail;