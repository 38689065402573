import React, { Component } from 'react';
import Img_preview from "../../webroot/images/preview_img.png";
import { Tab, Nav } from 'react-bootstrap';
import AgentUserDetailTab from './AgentUserDetailTab'
import MappedRegions from "./MappedRegionsTab"
import Reportees from "./ReporteesTab"

class AgentDetails extends Component {
  constructor(props) {
      super(props);
     // console.log(props)
      
      this.state = {

      };
  }

  componentDidMount(){
    window.addEventListener('scroll', () => {
       let activeClass = 'sticky-top';
       if(window.scrollY === 0){
           activeClass = 'top';
       }
       this.setState({ activeClass });
    });
}

  

  render() {

    return (
    <React.Fragment>
      <div className="container-fluid">
      <div className={`top-bar ${this.state.activeClass}`}>
        <div className="top-heading d-flex">
          <h1>Agent Detail</h1>
          <div className="right-btn btn-agent-detail">
            <button className="btn-line m-xs-l"> <i className="ic-history"></i> history</button>
            <button className="btn-line m-xs-l"><i className="ic-edit"></i> Edit</button>
            <button className="btn-primary m-xs-l">Approve</button>
            <button className="btn-line m-xs-l">Reject</button>
          </div>
        </div>
        </div>
        <div className="agent-detail-profile">
          <div className='role-detail-preview preview-confirmation'>
            <div className='profile-photo'>
              <img src={Img_preview} className="" />
            </div>
          </div>
          <div className='role-detail-preview d-flex'>
            <ul>
              <li>
                <span>User Details</span>
                <label>OTO Agent1</label>
              </li>
              <li>
                <span>Status</span>
                <label>In Process</label>
              </li>
              <li>
                <span>Current level</span>
                <label>NA</label>
              </li>
            </ul>
            <div className="profile-detail-role-detail">
              <span>Hierarchy</span>
              <ul>
                <li>Name (VP)</li>
                <li> Name (RBH)</li>
                <li> Name (ZH)</li>
                <li> Name (AM)</li>
                <li> Name (BM)</li>
                <li> Name (ARO)</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="agent-detail-tads">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">

            <Nav variant="pills" className="flex-column tab-line">
              <div className="tab-list">
                <Nav.Item>
                  <Nav.Link eventKey="first">User Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Mapped Regions</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="third">Reportees</Nav.Link>
                </Nav.Item>
              </div>

            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
               <AgentUserDetailTab />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <MappedRegions />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <Reportees />
              </Tab.Pane>

            </Tab.Content>

          </Tab.Container>
        </div>



      </div>
    </React.Fragment>
     )
    }
}

export default AgentDetails;