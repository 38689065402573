import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import loanbox_Illustration from "../../webroot/images/sfa_node_bg.svg";
import logo from "../../webroot/images/logo.svg";

const PageNotFound = (props) => {

    useEffect(() => {
        let header = document.getElementsByClassName("header")
        header[0].classList.add("hidden")
        let footer = document.getElementsByClassName("footer")
        footer[0].classList.add("hidden")
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">

                <div className='login-main'>

                    <div className="login-outer">
                        <div className="login-left-panel">
                            <div className="login-bg">

                                <div className="logo-img">
                                    {<img alt="" src={logo} className="" />}
                                </div>
                                <div className="login-txt">

                                    {/* <h1>Welcome to OTO SFA</h1> */}
                                </div>
                                <div></div>
                                <div></div>
                                <div className='login-bg-img'>
                                    {<img alt="" src={loanbox_Illustration} className="" />}
                                </div>
                            </div>
                        </div>
                        <div className="login-right-panel error-404">
                            {/* <div className="login-heading page_not_found">
                                <h1>404 Page Not Found</h1>
                            </div>
                            <div className="nav">
                            <NavLink to="/">Login</NavLink>
                            </div> */}
                            <div className="error-text">
                                <span>4</span><span className="shake-icon"><i className="ic-sad2"></i></span><span>4</span>
                            </div>
                            {/* <span className="gotohome">Go to home</span> */}
                            <div className="gotohome">
                            <NavLink to="/">Go to home</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="error-text f-bold">
            <span>4</span><span class="shake-icon"><i class="icon-sorry"></i></span><span>4</span>
    </div> */}
        </div>
    );
}

export default PageNotFound;
