import React, { useEffect, useState } from "react";
import { MasterService } from "../../services";
import InputField from "../element/CaptchaInputFields";

const Captcha = (props) => {

  const [captcha, setCaptcha] = useState('')
  const [captchaImg, setCaptchaImg] = useState({})
  const [captchaImgVal, setCaptchaImgVal] = useState({})


  useEffect(() => {
    loadCaptchaImage()
  }, [props.reloadCaptcha])

  /**
   *  Load Captcha Image
   */

  const loadCaptchaImage = (err = []) => {
    setCaptcha("");
    MasterService.get('/sfa/user/get_captcha_image').then(res=>{
        if(res && res.data && res.data.data){
            setCaptchaImg(res.data.data)
            setCaptchaImgVal(res.data);
        }
    })
  };

  /**
   * Input Handler
   * @param e (synthatic element)
   */
  const handleInputChange = async e => {
    let captchaVal = e.target.value;
    setCaptcha(captchaVal)
    props.onKeyPressCaptcha({ captchaVal: captchaVal, captchaData: captchaImgVal });
  };

  const { captchaError } = props;

  return (
      <div>

        <InputField
          inputProps={{
            id: "captcha",
            type: "text",
            name: "captcha",
            placeholder: "Captcha*",
            value: captcha,
            className: "form-input-captcha",
            maxLength: 6,
            dataerror: props.errField.captchaErrorMessage
          }}
          onChange={handleInputChange}
        />

        <i className="ic-survey icn-login"></i>
        <label data-label="" className="form-label"></label>

        <span className="password-show" style={{ top: "1px", left: "242px", "fontSize": '20px', "position": 'absolute', "right": '-30px' }}>
          {captchaError ? (
            ''
          ) : (
            <>
              <img src={captchaImg.image} alt="" style={{ width: '70%' }} />
              <span 
                onClick={loadCaptchaImage}
                resetCaptcha={loadCaptchaImage}
                title="reload captcha"
                className="reload"
              >
                &#x21bb;
              </span>
            </>
          )}
        </span>

      </div>
  );
}

export default Captcha;