import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import { MasterService } from "../../services";
import Loader from "../common/loader";
import RoleManagementTable from './RoleManagementTable'



const RoleManagement = (props) => {
    const [roleMapping, setRoleMapping] = useState([])
    const [roleRights, setRoleRights] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0)
    const [offset, setOffset] = useState(0);
    const [currentStatus, setCurrentStatus] = useState('1');
    const [sortRoleOrd, setSortRoleOrd] = useState(false);
    const [AddPrefrencepopup, setAddPrefrencepopup] = useState(false);

    useEffect(() => {
        getRolesMap(currentStatus, offset);
    }, [])


    useEffect(() => {
    }, [roleMapping])

   /*  const sortByName = () => {
        const sortedRole = [...roleMapping].sort((firstRole, secondRole) => {
            let firstName = firstRole.name, secondName = secondRole.name;
            if (firstName < secondName) {
                return -1;
            }
            if (firstName > secondName) {
                return 1;
            }
            return 0;
        })
        setSortRoleOrd(prev => !prev);
        setRoleMapping(sortedRole);
    } */

    const getRolesMap = async (status, pageNum) => {
        setLoading(true);
        MasterService.post('sfa/role/get_role_zone', { 'status': status, 'page_no': pageNum + 1 })
            .then(response => {
                if (response.status === 200) {
                    setRoleMapping(state => response.data.data)
                    if (response.data.pagination && response.data.pagination.total) {
                        let tempCount = Math.ceil(response.data.pagination.total / 50);
                        setPageCount(tempCount);
                    } else {
                        setPageCount(0);
                    }
                    setLoading(false);
                } else {
                    setLoading(false)
                }
            }).catch(err => {
                setLoading(false);
            });
    }

    const getRoleRights = async (roleId, roleName) => {
        setLoading(true);
        MasterService.post('sfa/role/get_role_rights', { 'role_id': roleId, 'role_name': roleName })
            .then(response => {
                if (response.status === 200) {
                    setRoleRights(response.data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }).catch(err => {
                setLoading(false);
            })
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        getRolesMap(currentStatus, selectedPage);
        setOffset(selectedPage);
        setSortRoleOrd(false);
        setRoleRights([]);
    };

    const showModalAddPrefrencepopup = () => {
        setAddPrefrencepopup(true);
        document.body.classList.add("overflow-hidden");
    }

    const hideModalAddPrefrencepopup = () => {
        setAddPrefrencepopup(false);
        document.body.classList.remove("overflow-hidden");
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="top-heading d-flex">
                    <h1>Role Management</h1>
                    {/* <div className="right-btn">
                        <button className="btn-line m-xs-l"><NavLink to="/add-role/role_details_info"><i className='ic-add m-sm-r'></i>Add Role</NavLink></button>
                    </div> */}
                </div>
                <div className='role-management-table-outer'>
                    <RoleManagementTable roleMapping={roleMapping} roleRights={roleRights} getRoleRights={getRoleRights} />
                </div>

                <div className="pagination-bx">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        forcePage={offset}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </div>

                <div className="bulk-upload-popup model-popup-outer">
                    <Modal show={AddPrefrencepopup} handleClose={hideModalAddPrefrencepopup} >
                        <div className="modal-header">
                            <h2>Upload User Data</h2>
                        </div>
                        <div className="modal-body">
                        </div>
                    </Modal>
                </div>
            </div>
        </React.Fragment>)

}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};


export default RoleManagement;