import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select';
import { useNavigate } from 'react-router-dom';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const AssignRights = (props) => {
    const { roleList, roleDetails } = props;
    const [roleData, setRoleData] = useState([])
    const [assignRights, setAssignRights] = useState({})
    const [errors, setErrors] = useState({})
    const [isEdit, setIsEdit] = useState(roleDetails && roleDetails.role_id && roleDetails.role_id !== "" ? true : false);
    const [loading, setLoading] = useState(false)

    const historyLead = useNavigate();

    useEffect(() => {
        if (roleDetails.assign_rights && roleDetails.role_id) {
            setAssignRights(roleDetails.assign_rights);
            let tempRoleDataArr = roleList.filter(item => roleDetails.assign_rights.role.includes(item.id));
            let [tempRoleData] = tempRoleDataArr
            setRoleData(tempRoleData.id)
        }
    }, [roleDetails])

    useEffect(() => {
        if (!roleDetails.role_id && roleDetails.assign_rights) {
            let tempRoleDataArr  = roleList.filter(item => roleDetails.assign_rights.role.includes(item.id));
            let [tempRoleData] = tempRoleDataArr;
            setRoleData(tempRoleData.id)
        }
    }, [roleData])

    const handleChange = async (name, selectedOption) => {
        console.log(name, selectedOption)
        let temp_role_details = { ...assignRights };
        let tempErrors = { ...errors };
        if (name === 'role') {
            temp_role_details['role'] = ""
            temp_role_details[name] = [selectedOption.id]
            setAssignRights(temp_role_details);
            setRoleData(selectedOption.id);
        }
        if(selectedOption.id !=''){
            tempErrors[name]='';
        }
        setErrors(tempErrors);        
    };

    const isValid = () => {
        let tempAssignRights = { ...assignRights }
        let tempErrors = { ...errors }
        let isFormVaild = true;
        console.log(assignRights, 'test')

        if (!tempAssignRights['role']) {
            isFormVaild = false;
            tempErrors['role'] = 'Role is required'
        }
        setErrors(tempErrors)
        return isFormVaild;
    }

    const handleClickNext = async () => {
        if (isValid()) {
            handleClick();
        }
    }

    const handleClick = async () => {
        await props.handleSave(assignRights, 'assign_rights')
        if (isEdit) {
            historyLead(`role_confirm?id=${roleDetails.role_id}`)
        } else {
            historyLead('role_confirm')
        }
    }

    return (
        <div className='basic-detail-form p-xxl add-user-detail-filed'>
            <h2>Reporting Details</h2>

            <h3>Reporting Manager Role</h3>

            <div className="row">
                 <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={roleList}
                                placeholder="Role*"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={roleList.filter(e => e.id === roleData)}
                                onChange={handleChange.bind(this, 'role')}
                            />
                        </div>
                        {
                            (errors && errors.role) ? <span className="error show absolute">{errors.role}</span> : ''
                        }
                    </fieldset>
                
            </div>

            <div className="br-top t-right">
                <button className='btn-primary' onClick={handleClickNext}>
                    Save & Continue
                </button>
            </div>
        </div>
    )
}


export default AssignRights;