import React, { useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import ReactPaginate from 'react-paginate';
import Loader from '../common/loader';
import { RoleId } from '../../config/constant';
import InputField from '../common/Input';
import { useNavigate } from 'react-router-dom';
import MasterService from './../../services/MasterService';
import { CSVLink } from 'react-csv';
import DateFormat from 'dateformat';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const DealerlistingTable = (props) => {
    const csvLink = useRef();
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [dealersData, setDealers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(1)
    const [offset, setOffset] = useState(0)
    const [search_text, setSearchText] = useState('')
    const historyLead = useNavigate();
    const [UserHistoryPopup, SetUserHistoryPopup] = useState(false);
    const [mappingHistoryCsvData, setmappingHistoryCsvData] = useState([])
    const [mappingHistoryData, setMappingHistoryData] = useState([]);
    const [selectedDealer,setSelectedDealer] = useState('0');
    const [selectedDealerName, setSelectedDealerName] = useState('');
    const [businessLine, setBusinessLine] = useState('');

    useEffect(() => {
        setDealers(props.dealersData)
        setPageCount(props.pageCount)
        setCityList(props.cityList)
        setStateList(props.stateList)
    }, [props.dealersData, props.pageCount, props.cityList, props.stateList])

    useEffect(() => {
        setOffset(0)
    }, [props.isPageReset])

    useEffect(() => {
        if (props.isReset) {
            handleReset()
        }
    }, [props.isReset])

    const handleReset = () => {
        setStateList([])
        setCityList([])
        setCity(null)
        setState(null)
        setDealers(null)
        setPageCount(1)
        setOffset(0)
        setSearchText('')
        handleSearch(true)
        setBusinessLine('')
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage)
        let search_data = {
            search_text: search_text ? search_text : '',
            city_id: city ? city.id : '',
            state_id: state ? state.id : '',
            business_line: businessLine.value,
        }
        props.handleSearchData(search_data)
        props.getDealerReporting(selectedPage + 1, search_data)
    };

    const handleChange = (event, name) => {
        if (name === "state") {
            setState(event)
            setCity(null)
            props.getCityList(event, true)
        } else if (name === "city") {
            setCity(event)
        } else if (name === 'business_line') {
            console.log("event.value", event.value);
            setBusinessLine(event);
        }
    }

    const handleInputChange = async (target_name, type, event) => {
        if (target_name === "search_text") {
            setSearchText(event.target.value)
        }
    }

    const handleSearch = (is_reset) => {
        setOffset(0)
        let search_data = {
            search_text: search_text ? search_text : '',
            city_id: city ? city.id : '',
            state_id: state ? state.id : '',
            business_line: businessLine.value,
            page: 1
        }
        if (is_reset) {
            search_data = {
                city_id: "",
                page: 1,
                search_text: "",
                state_id: "",
                vertical_id: 1,
                businessLine: ''
            }
        }
        props.handleSearchData(search_data)
        props.getDealerReporting(offset + 1, search_data)
    }

    const handleView = (dealer) => {
        let params = {
            city_id: dealer.city_id ? dealer.city_id : '',
            state_id: dealer.state_id ? dealer.state_id : '',
            dealer_id: dealer.dealer_code,
            city_name: dealer.city,
            state_name: dealer.state,
            dealer_name: dealer.dealer_name,
            type: 'dealer'
        }
        historyLead("/map-dealer", { state: params });
    }

    const showModalUserHistoryPopup = async (dealerId, dealerName) => {
        let postData = {
            "dealer_id": dealerId.toString(),
            "mapping_status": ""
        }
        setSelectedDealerName(dealerName)
        setSelectedDealer(dealerId.toString());
        setLoading(true);
        MasterService.post('/sfa/dealer/dealer-mapping-history', postData)
            .then(function (response) {
                if (response && response.data && response.data.status === 200 && response.data.data) {
                    setMappingHistoryData(response.data.data);
                }
            })
            .catch(function (response) {
            });
        SetUserHistoryPopup(true);
        setLoading(false);
    }

    const getMappingHistoryCsvData = async () => {
        let postData = {
            "dealer_id": selectedDealer,
            "mapping_status": ""
        }
        let result = []
        await MasterService.post('/sfa/dealer/export-dealer-mapping-history', postData)
            .then(function (response) {
                if (response && response.data && response.data.status === 200 && response.data.data.csv_data) {
                    result = response.data.data.csv_data;
                }
            })
            .catch(function (response) {
            });
        return result
    }

    const hideModalUserHistoryPopup = () => {
        SetUserHistoryPopup(false);
        document.body.classList.remove("overflow-hidden");
    }

   
    const downloadData = async () => {
        let csv_data = await getMappingHistoryCsvData()
        setmappingHistoryCsvData(csv_data)
        csvLink.current.link.click();
    }
    const isDate = (date) => {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    const businessLineList = props.configList.length ? props.configList.map((ele) => {
        return {id: ele.id, label: ele.business_line, value:ele.business_line_id}
    }) : [];

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="table-heading-filter">
                <h2>Dealer Listing</h2>
                <div className="filter-right-panel">
                    <fieldset className="search-bx">
                        <div className="material">
                            <InputField type="text" name="search_text" id="search_text" label="Search By Name Or Id" placeholder="Search By Name Or Id" value={search_text} onChange={handleInputChange.bind(null, 'search_text', 'text')} />
                            <i className="ic-search"></i>
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                value={state}
                                onChange={(e) => handleChange(e, 'state')}
                                options={stateList}
                                placeholder="Select State"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                value={city}
                                isDisabled={state && state.id ? false : true}
                                onChange={(e) => handleChange(e, 'city')}
                                options={cityList}
                                placeholder="Select City"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                value={businessLine}
                                onChange={(e) => handleChange(e, 'business_line')}
                                options={businessLineList}
                                placeholder="Business Line"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <div class="btn-submit-reset m-sm-l">
                        <button className='btn-primary' onClick={() => handleSearch(false)}>Search</button>
                        <button class="btn-reset" onClick={() => handleReset()}>Reset</button>
                    </div>
                </div>
            </div>

            <div className='dealer-listing-table-data data-table dealer-user-maping-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Dealer Details</th>
                            {/* <th>Products</th> */}
                            <th>City / State</th>
                            <th>Mapped Executive</th>
                            <th>Role</th>
                            <th>Business Line</th>
                            <th>Reporting Manager</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dealersData?.length > 0 && dealersData.map((dealers, index) => (
                            <tr key={index}>
                                <td className='v-top'>
                                    <span title={dealers.dealer_name} className='d-block show_ellipsis'>{dealers.dealer_name}</span>
                                    <span>{" (GCD-" + dealers.dealer_code + ")"}</span>
                                </td>
                                {/* <td className='v-top'>{dealers.mapped_product ? dealers.mapped_product : "UCF/UCRF/UBRF"}</td> */}
                                <td className='v-top'>
                                    <span title={dealers.city} className='d-block show_ellipsis'>{dealers.city}</span>
                                    <span>{dealers.state}</span>
                                </td>
                                <td className='map-dealer-column'>

                                    <table className='subtable'>
                                        <tbody>
                                            {dealers.mapped_data?.length > 0 && dealers.mapped_data.map((mapped_detail, mapped_index) => (
                                                <tr key={mapped_index}><td>
                                                    <span className='d-block' title={mapped_detail.mapped_executive}>{(mapped_detail.mapped_executive ? mapped_detail.mapped_executive : "-")}</span>
                                                    <span>{(mapped_detail.mapped_executive_id ? "SFA ID - " + mapped_detail.mapped_executive_id : "-")}</span>
                                                </td></tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table className='subtable'>
                                        <tbody>
                                            {dealers.mapped_data?.length > 0 && dealers.mapped_data.map((mapped_detail, mapped_index) => (
                                                <tr key={mapped_index}><td>{(mapped_detail.role_name ? mapped_detail.role_name : "-")}</td></tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table className='subtable'>
                                        <tbody>
                                            {dealers.mapped_data?.length > 0 && dealers.mapped_data.map((mapped_detail, mapped_index) => (
                                                <tr key={mapped_index}><td>{props.configList.length ? props.configList.find(obj => obj.business_line_id === mapped_detail.business_line).business_line : "-"}</td></tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table className='subtable'>
                                        <tbody>
                                            {dealers.mapped_data?.length > 0 && dealers.mapped_data.map((mapped_detail, mapped_index) => (
                                                <tr key={mapped_index}><td title={(mapped_detail.manager_name ? mapped_detail.manager_name + " (" + RoleId[mapped_detail.manager_role_id.toString()] + ")" : "-")}>{(mapped_detail.manager_name ? mapped_detail.manager_name + " (" + RoleId[mapped_detail.manager_role_id] + ")" : "-")}</td></tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>

                                <td>

                                    <table className='subtable'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul className="action-btn ul-li-horizontal" >
                                                        <li onClick={(e) => handleView(dealers)}><i className="ic-edit"></i></li>
                                                        <li onClick={() => showModalUserHistoryPopup(dealers.dealer_code, dealers.dealer_name)}>
                                                            <i className="ic-Call-history"></i>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <div className='pagination-bx' id="dealerUserList">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        forcePage={offset}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div >
            </div>

            <div className="model-popup-outer role-history-popup">
                <Modal show={UserHistoryPopup} handleClose={hideModalUserHistoryPopup} >
                    <div className="modal-header">
                        <div>
                        <h2>Mapping History ( {selectedDealerName ? selectedDealerName : ''} )</h2>
                        <h3>Dealer Mapping History</h3>
                        </div>
                        <div className="mapping-history-popup"><button className="btn-line">
                        <i class="ic-export m-xs-r"></i>
                        <span onClick={() => downloadData()} >Export Data</span>
                        <CSVLink
                            data={mappingHistoryCsvData}
                            filename={`dealer-mapping-history-${Math.round(+new Date() / 1000)}.csv`}
                            data-interception='off'
                            ref={csvLink}
                        >
                        </CSVLink>
                    </button></div>
                    </div>
                    
                    <div className='modal-body' >
                        <div className='data-table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Sales Officer</th>
                                        <th>Status</th>
                                        <th>Updated By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {mappingHistoryData.data?.length > 0 && mappingHistoryData.data.map((element, index) => (
                                <tr>
                                    <td>{isDate(element.created_date) ? DateFormat(new Date(element.created_date), 'dd mmm yyyy') : "-"}</td>
                                    <td><span>{element.user_name}</span></td>
                                    <td><span>{element.mapping_status == 1 ? "Mapped" : element.mapping_status == 0 ? "Unmapped" : ""}</span></td>
                                    <td><span>{element.update_by_name}</span></td>
                                </tr>
                            ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal>
            </div>

        </React.Fragment>
    )
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default DealerlistingTable;